const isKeyCode = ({ keyCode }, code) => keyCode && keyCode === code;

export const isEscKey = e => {
  if (isKeyCode(e, 27)) return true;
  return false;
};

export const isBackspaceKey = e => {
  if (isKeyCode(e, 8)) return true;
  return false;
};

export const isTabKey = e => {
  if (isKeyCode(e, 9)) return true;
  return false;
};

export const isEnterKey = e => {
  if (isKeyCode(e, 13)) return true;
  return false;
};

export const isSpaceBar = e => {
  if (isKeyCode(e, 32)) return true;
  return false;
};
