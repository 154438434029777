import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/core';

import { ReactComponent as SpinnerSvg } from './assets/icons/spinner.svg';

const spin = keyframes`
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 360deg);
  }
`;

const baseIconStyles = ({ theme }) => css`
  width: 100%;
  height: 100%;
  & > path {
    fill: ${theme.colors.white};
  }
`;

const baseSpinStyles = css`
  label: spinner;
  & > path {
    animation: ${spin} 1s infinite linear;
    transform-origin: 50% 50%;
  }
`;

const SpinnerIcon = styled(SpinnerSvg)`
  ${baseIconStyles};
  ${baseSpinStyles};
`;

const baseContainerStyles = css`
  opacity: 0;
  max-width: fit-content;
  position: relative;
  transition: opacity 200ms ease-in-out;
  width: 18px;
`;

const activeContainerStyles = ({ active }) =>
  active &&
  css`
    opacity: 1;
  `;

const SpinnerContainer = styled('div')(
  baseContainerStyles,
  activeContainerStyles
);

const Spinner = ({ active, ...props }) => (
  <SpinnerContainer {...{ active, ...props }}>
    <SpinnerIcon />
  </SpinnerContainer>
);

Spinner.propTypes = {
  active: PropTypes.bool
};

Spinner.defaultProps = {
  active: true
};

export default Spinner;
