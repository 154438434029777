import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { textMega } from 'styling/helpers';

const placeholderTextBaseStyles = ({ theme }) => css`
  ${textMega({ theme })};
  color: ${theme.colors.n500};
  transition: color ${theme.transitions.default};
  font-weight: ${theme.fontWeight.regular};
  position: absolute;
  top: 0;
  left: 0;
  padding: ${theme.spacings.byte} ${theme.spacings.kilo};
  width: 100%;
  z-index: ${theme.zIndex.absolute};
`;

const EditorPlaceholder = styled('div')(placeholderTextBaseStyles);

export default EditorPlaceholder;
