import React from 'react';
import PropTypes from 'prop-types';

import { ModalProvider, ModalConsumer } from 'modal/ModalProvider';
import Button from 'chrome/Button';

const ModalTrigger = ({ children, btnText, extra, onSelect }) => (
  <ModalProvider>
    <ModalConsumer>
      {({ setModal }) => {
        const modalContent = () =>
          setModal({
            children: () => children
          });

        return (
          <Button
            {...extra}
            type="button"
            onClick={() => {
              if (onSelect) {
                onSelect();
              }
              modalContent();
            }}
          >
            {btnText}
          </Button>
        );
      }}
    </ModalConsumer>
  </ModalProvider>
);

ModalTrigger.propTypes = {
  children: PropTypes.object,
  btnText: PropTypes.string,
  extra: PropTypes.object,
  onSelect: PropTypes.func
};

ModalTrigger.defaultProps = {
  children: () => {}
};

export default ModalTrigger;
