import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';

const baseStyles = ({ theme }) => css`
  width: 100%;
  max-width: ${theme.elementSize.content};
`;

const Wrapper = styled('div')(baseStyles);

Wrapper.propTypes = {
  children: PropTypes.node
};

export default Wrapper;
