import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import Wrapper from './FeedbackWrapper';
import Row from './FeedbackRow';
import Col from './FeedbackCol';
import Header from './FeedbackHeader';
import Content from './FeedbackContent';
import Actions from './FeedbackActions';
import Empty from './FeedbackEmpty';
import List from './FeedbackList';
import Item from './FeedbackItem';
import Title from './FeedbackTitle';
import Replacement from './FeedbackReplacement';
import OldWord from './FeedbackOldWord';
import NewWord from './FeedbackNewWord';
import Counter from './FeedbackCounter';
import HeaderWrapper from './FeedbackHeaderWrapper';
import ScrollBox from './FeedbackScrollBox';

const baseStyles = ({ theme }) => css`
  width: 100%;
  margin-bottom: ${theme.spacings.kilo};
`;

const marginStyles = ({ theme, isLastGroup }) =>
  isLastGroup &&
  css`
    margin-top: ${theme.spacings.exa};
  `;

const StyledFeedback = styled('div')(baseStyles, marginStyles);

const Feedback = ({ children, isLastGroup }) => (
  <StyledFeedback isLastGroup={isLastGroup}>{children}</StyledFeedback>
);

Feedback.propTypes = {
  children: PropTypes.node,
  isLastGroup: PropTypes.bool
};

Feedback.defaultProps = {
  children: ''
};

Feedback.Wrapper = Wrapper;
Feedback.Row = Row;
Feedback.Col = Col;
Feedback.Header = Header;
Feedback.Content = Content;
Feedback.Actions = Actions;
Feedback.Empty = Empty;
Feedback.List = List;
Feedback.Item = Item;
Feedback.Title = Title;
Feedback.Replacement = Replacement;
Feedback.OldWord = OldWord;
Feedback.NewWord = NewWord;
Feedback.Counter = Counter;
Feedback.HeaderWrapper = HeaderWrapper;
Feedback.ScrollBox = ScrollBox;

export default Feedback;
