import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import NavMenuList from 'chrome/NavMenuList';
import NavMenuItem from 'chrome/NavMenuItem';

const baseStyles = ({ theme }) => css`
  margin-top: ${theme.spacings.zetta};
  margin-bottom: ${theme.spacings.Yotta};
`;

const Container = styled('nav')(baseStyles);

const NavMenu = ({ children }) => <Container>{children}</Container>;

NavMenu.propTypes = {
  children: PropTypes.node
};

NavMenu.List = NavMenuList;
NavMenu.Item = NavMenuItem;

export default NavMenu;
