export const SET_TOOLTIP_OPEN = 'SET_TOOLTIP_OPEN';
export const setTooltipToOpen = id => {
  return {
    type: SET_TOOLTIP_OPEN,
    id
  };
};

export const SET_ACCORDION_OPEN = 'SET_ACCORDION_OPEN';
export const setAccordionToOpen = id => {
  return {
    type: SET_ACCORDION_OPEN,
    id
  };
};

export const SET_ACCORDION_TOGGLE_COMPLETE = 'SET_ACCORDION_TOGGLE_COMPLETE';
export const setAccordionToggleComplete = id => {
  return {
    type: SET_ACCORDION_TOGGLE_COMPLETE,
    id
  };
};
