import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { transparentize } from 'polished';

import Tooltip from 'ui/Tooltip';

const baseStyles = ({ theme }) => css`
  color: ${theme.colors.white};
  background-color: ${theme.colors.g700};
  border-radius: ${theme.borderRadius.giga};
  font-size: ${theme.typography.text.mega.fontSize};
  padding: ${theme.spacings.bit} ${theme.spacings.byte};
  transition: background-color ${theme.transitions.default};
  cursor: pointer;
  border: 0;
  padding: ${theme.spacings.byte} ${theme.spacings.mega};
`;

const activeStyles = ({ theme, isActive }) =>
  isActive &&
  css`
    background-color: ${transparentize(0.15, theme.colors.g700)};
  `;

const StyledWord = styled('button')(baseStyles, activeStyles);

const containerBaseStyles = () =>
  css`
    position: relative;
  `;

const Container = styled('div')(containerBaseStyles);

const NewWord = ({
  children,
  disabled,
  tooltipText,
  wordId,
  findWord,
  replaceWord,
  onSelect
}) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <Container>
      {isActive && (
        <Tooltip position="top" size="bit">
          {tooltipText}
        </Tooltip>
      )}
      <StyledWord
        disabled={disabled}
        isActive={isActive}
        onClick={() =>
          onSelect({
            id: wordId,
            findWord,
            replaceWord
          })
        }
        onMouseEnter={() => setIsActive(true)}
        onMouseLeave={() => setIsActive(false)}
      >
        {children}
      </StyledWord>
    </Container>
  );
};

NewWord.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  wordId: PropTypes.number,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  tooltipText: PropTypes.string,
  findWord: PropTypes.string,
  replaceWord: PropTypes.string
};

NewWord.defaultProps = {
  disabled: false,
  tooltipText: 'Click to make this replacement.'
};

export default NewWord;
