import React from 'react';
import PropTypes from 'prop-types';

import EditorItem from './EditorItem';

const EditorList = ({ list }) =>
  list.map(({ ...props }, index) => <EditorItem key={index} {...props} />);

EditorList.propTypes = {
  list: PropTypes.array
};

export default EditorList;
