import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { transparentize } from 'polished';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { textMega } from 'styling/helpers';
import { setFocusState } from './actions';

import EditorLabel from './EditorLabel';
import EditorPlaceholder from './EditorPlaceholder';
import EditorInput from './EditorInput';

const editorContainerBaseStyles = ({ theme }) => css`
  width: 100%;
  min-height: 80px;
  color: ${theme.colors.n900};
  display: block;
  position: relative;
  margin-bottom: ${theme.spacings.mega};

  label > &,
  label + & {
    margin-top: ${theme.spacings.bit};
  }
`;

const EditorContainer = styled('div')(editorContainerBaseStyles);

const editorBaseStyles = ({ theme }) => css`
  border-width: 1px;
  border-style: solid;
  border-color: ${theme.colors.n300};
  border-radius: ${theme.borderRadius.giga};
  display: block;
  width: 100%;
  min-height: 80px;
  overflow: auto;
  padding: ${theme.spacings.byte} ${theme.spacings.kilo};
  margin-bottom: ${theme.spacings.byte};
  transition: border-color ${theme.transitions.default};
  outline: 0;
  cursor: text;
  position: relative;
  z-index: ${theme.zIndex.textbox};

  ${textMega({ theme })};
  font-weight: ${theme.fontWeight.regular};

  p {
    margin: 0;
    margin-bottom: ${theme.spacings.kilo};
    padding: 0;
    min-height: 30px;
  }
`;

const editorFocusStyles = ({ theme, isFocused }) =>
  isFocused &&
  css`
    border: 1px solid ${theme.colors.n800};
  `;

const editorValidStyles = ({ theme, showValid }) =>
  showValid &&
  css`
    &:not(:focus) {
      border-color: ${theme.colors.g500};
      &::placeholder {
        color: ${theme.colors.g500};
      }
    }
  `;

const editorWarningStyles = ({ theme, hasWarning }) =>
  hasWarning &&
  css`
    &:not(:focus) {
      border-color: ${theme.colors.y500};
      &::placeholder {
        color: ${theme.colors.y500};
      }
    }
  `;

const editorInvalidStyles = ({ theme, invalid }) =>
  invalid &&
  css`
    &:not(:focus) {
      border-color: ${theme.colors.r300};
      &::placeholder {
        color: ${theme.colors.r300};
      }
    }
  `;

const editorOptionalStyles = ({ theme, optional }) =>
  optional &&
  css`
    padding-top: ${theme.spacings.bit};
  `;

const editorSubmittingStyles = ({ theme, isSubmitting }) =>
  isSubmitting &&
  css`
    background: ${transparentize(0.9, theme.colors.shadow)};
    pointer-events: none;
  `;

const editorExportingStyles = ({ theme, isExporting }) =>
  isExporting &&
  css`
    background: ${transparentize(0.9, theme.colors.shadow)};
    pointer-events: none;
  `;

const EditorContent = styled('div')(
  editorBaseStyles,
  editorFocusStyles,
  editorValidStyles,
  editorWarningStyles,
  editorInvalidStyles,
  editorOptionalStyles,
  editorSubmittingStyles,
  editorExportingStyles
);

const EditorItem = ({
  id,
  isSubmitting,
  isExporting,
  invalid,
  hasWarning,
  showValid,
  isFocused,
  hasText,
  placeholderText,
  handlePlaceholder,
  ...rest
}) => {
  const showPlaceholder = !isFocused && !hasText;

  return (
    <>
      <EditorLabel
        {...{
          id,
          ...rest
        }}
      />
      <EditorContainer
        onClick={() => handlePlaceholder(id)}
        onBlur={() => isFocused && handlePlaceholder()}
      >
        {showPlaceholder && (
          <EditorPlaceholder>{placeholderText}</EditorPlaceholder>
        )}
        <EditorContent
          {...{
            showValid,
            invalid,
            hasWarning,
            isFocused,
            isSubmitting,
            isExporting
          }}
        >
          <EditorInput id={id} />
        </EditorContent>
      </EditorContainer>
    </>
  );
};

EditorItem.propTypes = {
  id: PropTypes.string,
  invalid: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isExporting: PropTypes.bool,
  hasWarning: PropTypes.bool,
  showValid: PropTypes.bool,
  isFocused: PropTypes.bool,
  hasText: PropTypes.bool,
  placeholderText: PropTypes.string,
  handlePlaceholder: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  const { editor } = state;
  const { byCleaned, isSubmitting, isExporting, focusedFormField } = editor;

  const isFocused = focusedFormField === id;
  const text = byCleaned[id];
  const hasText = !!text;

  return {
    isFocused,
    isSubmitting,
    isExporting,
    hasText
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handlePlaceholder: id => dispatch(setFocusState(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditorItem);
