import run from 'app/configure/run';

const app = {
  run: (config, elementId) => {
    run(
      {
        ...config
      },
      elementId
    );
  }
};

app.run(
  {
    isDev: process.env.NODE_ENV === 'development',
    apiUrl: process.env.REACT_APP_API_GATEWAY_URL,
    jwt: {
      privateKey: process.env.REACT_APP_JWT_PRIVATE_KEY
    }
  },
  'root'
);
