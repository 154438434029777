export const byDimissedItem = feedback => {
  return {
    ...feedback,
    isDismissed: true
  };
};

export const byAcceptedItem = feedback => {
  return {
    ...feedback,
    isAccepted: true
  };
};

export const byFeedbackType = itemType => ({ type }) => type === itemType;

export const byDismissed = arr => ({ id }) => arr.includes(id);

export const byReplaced = arr => ({ wordIds }) => arr.includes(wordIds[0]);

export const byAccepted = arr => ({ id }) => arr.includes(id);

export const byExcludedItems = ({ dismissed, replaced, accepted }) => ({
  id,
  wordIds
}) =>
  !dismissed.includes(id) &&
  !replaced.includes(wordIds[0]) &&
  !accepted.includes(id);
