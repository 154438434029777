import React from 'react';
import { Global, css } from '@emotion/core';

import 'normalize.css';

const createBaseStyles = () => css`
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    background-color: #fff;
    color: #333333;
    font-family: Helvetica, Arial, sans-serif;
    letter-spacing: 0.3px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.4;
    margin: 0;
    padding: 0;
  }
`;

const BaseStyles = () => <Global styles={() => createBaseStyles()} />;

BaseStyles.propTypes = {};

BaseStyles.defaultProps = {
  custom: ''
};

export default BaseStyles;
