import React, { Component, createContext } from 'react';

import { childrenPropType } from 'lib/shared-prop-types';
import Modal, { TRANSITION_DURATION } from './Modal';

const { Provider: ContextProvider, Consumer: ModalConsumer } = createContext({
  setModal: () => {},
  closeModal: () => {},
  getModal: () => {},
  disableClose: () => {},
  enableClose: () => {}
});

export { ModalConsumer };

export class ModalProvider extends Component {
  static propTypes = {
    children: childrenPropType.isRequired
  };

  state = {
    modal: null,
    isOpen: false,
    closeIsActive: true
  };

  componentDidUpdate(prevProps, { isOpen: prevIsOpen }) {
    const { isOpen } = this.state;

    if (!isOpen && prevIsOpen) {
      setTimeout(
        () =>
          this.setState(prevState => ({
            ...prevState,
            modal: null
          })),
        TRANSITION_DURATION
      );
    }
  }

  setModal = config => {
    window.onpopstate = this.closeModal;

    this.setState(prevState => ({
      ...prevState,
      modal: {
        ...prevState.modal,
        ...config
      },
      isOpen: true
    }));
  };

  closeModal = () => {
    window.onpopstate = null;
    this.setState(prevState => ({
      ...prevState,
      isOpen: false
    }));
  };

  disableClose = () => {
    window.onpopstate = null;
    this.setState(prevState => ({
      ...prevState,
      closeIsActive: false
    }));
  };

  enableClose = () => {
    window.onpopstate = null;
    this.setState(prevState => ({
      ...prevState,
      closeIsActive: true
    }));
  };

  contextValue = {
    getModal: () => this.state.modal,
    setModal: this.setModal,
    closeModal: this.closeModal,
    disableClose: this.disableClose,
    enableClose: this.enableClose
  };

  render() {
    const { modal, isOpen, closeIsActive } = this.state;
    const { children, onClose = () => {}, ...otherProps } = modal || {};

    const handleClose = () => {
      onClose();
      this.closeModal();
    };

    const modalProps = modal
      ? {
          isOpen,
          closeIsActive,
          ...otherProps,
          children: () =>
            children({
              onClose: handleClose
            }),
          onClose: handleClose
        }
      : {
          isOpen,
          closeIsActive,
          onClose,
          children: () => null
        };

    return (
      <ContextProvider value={this.contextValue}>
        {this.props.children}
        {modal && <Modal {...modalProps} />}
      </ContextProvider>
    );
  }
}
