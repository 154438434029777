import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AnimateHeight from 'react-animate-height';
import { getHashcode } from 'lib/utils';
import { selectWordToMakeActive } from 'editor/actions';
import {
  setAccordionOpen,
  setActiveAccordion,
  setAccordionToggleComplete
} from 'ui/actions';
import {
  setFeedbackAsDismissed,
  setFeedbackAsEnabled,
  setFeedbackAsReplaced,
  setFeedbackAsAccepted,
  undoFeedbackAsReplaced,
  undoFeedbackAsAccepted,
  submitFeedback
} from './actions';
import {
  byDimissedItem,
  byAcceptedItem,
  byFeedbackType,
  byDismissed,
  byReplaced,
  byAccepted,
  byExcludedItems
} from './FeedbackUtils';
import Feedback from './Feedback';

const ItemContent = ({
  id,
  type,
  openId,
  wordIds,
  itemIsOpened,
  themeName,
  feedback,
  isDismissed,
  isReplaced,
  isAccepted,
  find,
  replacement,
  duration,
  handleAccordionToggle,
  dismissOnClick,
  replaceOnClick,
  acceptOnClick,
  undoDismissOnClick,
  undoReplaceOnClick,
  undoAcceptOnClick,
  onAnimationEnd
}) => {
  const isSelected = openId === id;
  const isReplacement = type === 'replacement';
  const isSuggestion = type === 'suggestion';
  const height = isSelected ? 'auto' : 0;
  const showActions = isSelected && itemIsOpened;
  const replaceFindWord = isReplacement ? find[0] : null;
  const openFeedback = () =>
    !isSelected ? handleAccordionToggle(id) : handleAccordionToggle(null);

  return (
    <>
      <Feedback.Row
        {...{
          isDismissed,
          isReplaced,
          isAccepted
        }}
      >
        <Feedback.Header
          {...{
            isSuggestion,
            isDismissed,
            isReplaced,
            isAccepted,
            title: themeName,
            text: replaceFindWord,
            showToggleIcon: !showActions,
            onSelect: openFeedback
          }}
        />
        <AnimateHeight
          {...{
            height,
            duration,
            onAnimationEnd
          }}
        >
          <Feedback.Wrapper>
            {isReplacement && (
              <Feedback.Replacement>
                <Feedback.OldWord>{replaceFindWord}</Feedback.OldWord>
                <Feedback.NewWord
                  {...{
                    disabled: isReplaced,
                    wordId: wordIds[0],
                    findWord: replaceFindWord,
                    replaceWord: replacement,
                    onSelect: replaceOnClick
                  }}
                >
                  {replacement}
                </Feedback.NewWord>
              </Feedback.Replacement>
            )}
            <Feedback.Content>{feedback}</Feedback.Content>
          </Feedback.Wrapper>
        </AnimateHeight>
      </Feedback.Row>
      {showActions && (
        <Feedback.Col>
          <Feedback.Actions
            {...{
              id,
              wordIds,
              type,
              findWord: replaceFindWord,
              replaceWord: replacement,
              isDismissed,
              isReplaced,
              isAccepted,
              dismissOnClick,
              replaceOnClick,
              acceptOnClick,
              undoDismissOnClick,
              undoReplaceOnClick,
              undoAcceptOnClick
            }}
          />
        </Feedback.Col>
      )}
    </>
  );
};

class FeedbackGroup extends Component {
  feedbackRefs = [];

  componentDidMount = () => {
    this.feedbackRefs = this.props.list.reduce((acc, value) => {
      acc[value.id] = React.createRef();
      return acc;
    }, {});
  };

  componentDidUpdate = prevProps => {
    const { openId, scrollActive } = this.props;

    if (
      scrollActive &&
      this.feedbackRefs[openId] &&
      prevProps.openId !== openId
    ) {
      this.feedbackRefs[openId].current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
    }
  };

  render = () => {
    const { title, list, count, scrollActive, isLastGroup } = this.props;

    return (
      <Feedback isLastGroup={isLastGroup}>
        <Feedback.HeaderWrapper>
          <Feedback.Counter>{count}</Feedback.Counter>
          <Feedback.Title>{title}</Feedback.Title>
        </Feedback.HeaderWrapper>
        <Feedback.ScrollBox isActive={scrollActive}>
          <Feedback.List>
            {list.map((item, key) => (
              <Feedback.Item key={key} ref={this.feedbackRefs[item.id]}>
                <ItemContent
                  {...{
                    ...item,
                    ...this.props
                  }}
                />
              </Feedback.Item>
            ))}
          </Feedback.List>
        </Feedback.ScrollBox>
      </Feedback>
    );
  };
}

const FeedbackPresenter = ({
  isSubmitted,
  isSubmitting,
  replacementList,
  suggestionList,
  replacedList,
  hasReplacements,
  counter,
  ...props
}) =>
  isSubmitting || !isSubmitted ? (
    <Feedback.Empty />
  ) : (
    <>
      <FeedbackGroup
        {...{
          list: replacementList,
          title: 'Suggested Replacements',
          count: counter.replacement,
          scrollActive: counter.replacement > 5,
          ...props
        }}
      />
      <FeedbackGroup
        {...{
          list: suggestionList,
          title: 'Suggested Improvements',
          count: counter.suggestion,
          scrollActive: counter.suggestion > 5,
          ...props
        }}
      />
      {hasReplacements && (
        <FeedbackGroup
          {...{
            list: replacedList,
            title: 'Replaced',
            isReplaced: true,
            isLastGroup: true,
            count: counter.replaced,
            scrollActive: counter.replaced > 5,
            ...props
          }}
        />
      )}
    </>
  );

FeedbackPresenter.propTypes = {
  id: PropTypes.number,
  type: PropTypes.string,
  openId: PropTypes.string,
  wordIds: PropTypes.array,
  itemIsOpened: PropTypes.bool,
  themeName: PropTypes.string,
  feedback: PropTypes.string,
  isDismissed: PropTypes.bool,
  isReplaced: PropTypes.bool,
  find: PropTypes.array,
  replacement: PropTypes.string,
  duration: PropTypes.number,
  feedbackCount: PropTypes.number,
  handleAccordionToggle: PropTypes.func,
  dismissOnClick: PropTypes.func,
  enableOnClick: PropTypes.func,
  replaceOnClick: PropTypes.func,
  undoReplaceOnClick: PropTypes.func,
  onAnimationEnd: PropTypes.func,
  isSubmitted: PropTypes.bool,
  feedbackList: PropTypes.array,
  replacedList: PropTypes.array,
  hasReplacements: PropTypes.bool,
  list: PropTypes.array,
  title: PropTypes.string
};

FeedbackGroup.propTypes = FeedbackPresenter.propTypes;
ItemContent.propTypes = {
  ...FeedbackPresenter.propTypes,
  id: PropTypes.string
};

FeedbackPresenter.defaultProps = {
  isSubmitted: false,
  duration: 400
};

const mapStateToProps = state => {
  const { editor, feedback, ui } = state;
  const { isSubmitting, isSubmitted } = editor;
  const { accordionOpenId, accordionIsOpen } = ui;
  const { allIds, dismissedIds, replacedWords, acceptedIds } = feedback;

  const openId = accordionOpenId;
  const itemIsOpened = accordionIsOpen;

  const allFeedback = allIds.map(id => {
    const item = feedback.byId[id];
    const { find } = item;

    const wordIds = find.reduce(
      (arr, curr) => arr.concat(getHashcode(curr.trim())),
      []
    );

    return {
      ...item,
      wordIds,
      isDismissed: false,
      isAccepted: false
    };
  });

  const filterdList = allFeedback.filter(
    byExcludedItems({
      dismissed: dismissedIds,
      replaced: replacedWords,
      accepted: acceptedIds
    })
  );

  const allReplacements = filterdList.filter(byFeedbackType('replacement'));

  const allSuggestions = filterdList.filter(byFeedbackType('suggestion'));

  const allReplaced = allFeedback.filter(byReplaced(replacedWords));

  const allReplacementsDismissed = allFeedback
    .map(byDimissedItem)
    .filter(byFeedbackType('replacement'))
    .filter(byDismissed(dismissedIds));

  const allSuggestionsDismissed = allFeedback
    .map(byDimissedItem)
    .filter(byFeedbackType('suggestion'))
    .filter(byDismissed(dismissedIds));

  const allSuggestionsAccepted = allFeedback
    .map(byAcceptedItem)
    .filter(byFeedbackType('suggestion'))
    .filter(byAccepted(acceptedIds));

  const replacementList = [...allReplacements, ...allReplacementsDismissed];
  const suggestionList = [
    ...allSuggestions,
    ...allSuggestionsDismissed,
    ...allSuggestionsAccepted
  ];
  const replacedList = allReplaced;

  const counter = {
    replacement: replacementList.length,
    suggestion: suggestionList.length,
    replaced: replacedList.length
  };

  return {
    isSubmitting,
    isSubmitted,
    openId,
    itemIsOpened,
    replacementList,
    suggestionList,
    replacedList,
    counter,
    hasReplacements: !!allReplaced.length
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAnimationEnd: id => dispatch(setAccordionToggleComplete(id)),
    handleAccordionToggle: id => {
      dispatch(setAccordionOpen(id));
      dispatch(selectWordToMakeActive(id));
    },
    dismissOnClick: id => {
      dispatch(setFeedbackAsDismissed(id));
      dispatch(selectWordToMakeActive());
      dispatch(setAccordionOpen());
      dispatch(submitFeedback(id, "save", "rejected"));
    },
    replaceOnClick: (id, data) => {
      dispatch(setFeedbackAsReplaced(data));
      dispatch(setActiveAccordion());
      dispatch(selectWordToMakeActive());
      dispatch(submitFeedback(id, "save", "accepted"));
    },
    acceptOnClick: id => {
      dispatch(setFeedbackAsAccepted(id));
      dispatch(setActiveAccordion());
      dispatch(submitFeedback(id, "save", "accepted"));
    },
    undoDismissOnClick: id => {
      dispatch(setFeedbackAsEnabled(id));
      dispatch(setAccordionOpen());
      dispatch(submitFeedback(id, "delete", "rejected"));
    },
    undoReplaceOnClick: (id, data) => {
      dispatch(undoFeedbackAsReplaced(data));
      dispatch(setAccordionOpen());
      dispatch(submitFeedback(id, "delete", "accepted"));
    },
    undoAcceptOnClick: id => {
      dispatch(undoFeedbackAsAccepted(id));
      dispatch(setAccordionOpen());
      dispatch(submitFeedback(id, "delete", "accepted"));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackPresenter);
