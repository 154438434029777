export const SET_FORM_FIELD_VALUE = 'SET_FORM_FIELD_VALUE';
export const setFormFieldValue = data => {
  return {
    type: SET_FORM_FIELD_VALUE,
    ...data
  };
};

export const SET_FORM_HISTORY = 'SET_FORM_HISTORY';
export const setFormHistory = data => {
  return {
    type: SET_FORM_HISTORY,
    ...data
  };
};

export const SET_SUBMISSION_DATA = 'SET_SUBMISSION_DATA';
export const setSubmissionData = data => {
  return {
    type: SET_SUBMISSION_DATA,
    data
  };
};

export const FORM_SUBMIT_START = 'FORM_SUBMIT_START';
export const formSubmitStart = () => {
  return {
    type: FORM_SUBMIT_START
  };
};

export const FORM_SUBMIT_SUCCESS = 'FORM_SUBMIT_SUCCESS';
export const formSubmitSuccess = resp => {
  return {
    type: FORM_SUBMIT_SUCCESS,
    resp
  };
};

export const FORM_SUBMIT_FAILURE = 'FORM_SUBMIT_FAILURE';
export const formSubmitFailure = err => {
  return {
    type: FORM_SUBMIT_FAILURE,
    err
  };
};

export const SET_WORD_DICTIONARY = 'SET_WORD_DICTIONARY';
export const setWordDictionary = data => {
  return {
    type: SET_WORD_DICTIONARY,
    data
  };
};

export const CLEAR_FORM_HISTORY = 'CLEAR_FORM_HISTORY';
export const clearFormHistory = data => {
  return {
    type: CLEAR_FORM_HISTORY,
    ...data
  };
};

export const REPLACE_WORDS_WITH_REPLACEMENT = 'REPLACE_WORDS_WITH_REPLACEMENT';
export const replaceWordsWithReplacement = id => {
  return {
    type: REPLACE_WORDS_WITH_REPLACEMENT,
    id
  };
};

export const SET_ACTIVE_WORD_ID = 'SET_ACTIVE_WORD_ID';
export const setActiveWordId = id => {
  return {
    type: SET_ACTIVE_WORD_ID,
    id
  };
};

export const SET_EDITOR_FOCUS_STATE = 'SET_EDITOR_FOCUS_STATE';
export const setFocusState = id => {
  return {
    type: SET_EDITOR_FOCUS_STATE,
    id
  };
};

export const SET_FORM_TO_UPDATED_VALUES = 'SET_FORM_TO_UPDATED_VALUES';
export const setFormValuesToUpdatedValues = data => {
  return {
    type: SET_FORM_TO_UPDATED_VALUES,
    data
  };
};

export const SET_FORM_SUBMISSION_ID = 'SET_FORM_SUBMISSION_ID';
export const setSubmissionId = id => {
  return {
    type: SET_FORM_SUBMISSION_ID,
    id
  };
};

export const SET_UPDATED_FORM_FIELD_VALUE = 'SET_UPDATED_FORM_FIELD_VALUE';
export const setUpdatedFormFieldValue = data => {
  return {
    type: SET_UPDATED_FORM_FIELD_VALUE,
    ...data
  };
};

export const SET_VALIDATION_MESSAGE_VISIBILITY =
  'SET_VALIDATION_MESSAGE_VISIBILITY';
export const setValidationMessageVisibility = () => {
  return {
    type: SET_VALIDATION_MESSAGE_VISIBILITY
  };
};

export const STORE_FORM_FIELD_VALUE = 'STORE_FORM_FIELD_VALUE';
export const storeFormFieldValue = data => {
  return {
    type: STORE_FORM_FIELD_VALUE,
    ...data
  };
};

export const UNDO_REPLACED_WORDS = 'UNDO_REPLACED_WORDS';
export const undoReplacedWords = id => {
  return {
    type: UNDO_REPLACED_WORDS,
    id
  };
};
