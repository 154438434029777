import React from 'react';
import PropTypes from 'prop-types';

import noScroll from 'no-scroll';
import ReactModal from 'react-modal';
import styled from '@emotion/styled';
import { css as emotionCss, ClassNames } from '@emotion/core';
import { withTheme } from 'emotion-theming';
import { transparentize } from 'polished';

import { mapValues } from 'lib/fp';
import { themePropType } from 'lib/shared-prop-types';
import { isFunction } from 'lib/type-check';

import { ReactComponent as CloseIcon } from './assets/icons/close.svg';

export const TRANSITION_DURATION = 200;
export const DEFAULT_APP_ELEMENT = '#root';

const TRANSFORM_Y_FLOATING = '10vh';
const FLOATING_TRANSITION = `${TRANSITION_DURATION}ms ease-in-out`;
const FIXED_TRANSITION = `${TRANSITION_DURATION}ms cubic-bezier(0, 0.37, 0.64, 1)`;

const modalClassName = css => ({
  base: ({ theme }) => css`
    outline: none;

    ${theme.mq.untilKilo} {
      bottom: 0;
      max-height: 80vh;
      -webkit-overflow-scrolling: touch;
      overflow-y: auto;
      position: fixed;
      transform: translateY(100%);
      transition: transform ${FIXED_TRANSITION};
    }

    ${theme.mq.kilo} {
      transition: transform ${FLOATING_TRANSITION},
        opacity ${FLOATING_TRANSITION}
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `,

  afterOpen: () => css``,

  beforeClose: ({ theme }) => css`
    ${theme.mq.untilKilo} {
      transform: translateY(100%);
    }

    ${theme.mq.kilo} {
      opacity: 0;
      transform: translateY(${TRANSFORM_Y_FLOATING});
    }
  `
});

const overlayClassName = css => ({
  base: ({ theme }) => css`
    background: ${transparentize(0.84, theme.colors.shadow)};
    bottom: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 200ms ease-in-out;
    z-index: ${theme.zIndex.modal};

    ${theme.mq.kilo} {
      -webkit-overflow-scrolling: touch;
      overflow-y: auto;
    }
  `,

  afterOpen: () => css`
    opacity: 1;
  `,

  beforeClose: () => css`
    opacity: 0;
  `
});

const modalContainerStyles = ({ theme }) => emotionCss`
  display: flex;
  background-color: ${theme.colors.white};
  border-radius: ${theme.borderRadius.giga};
  box-shadow: 0 1px 7px 0 ${transparentize(0.75, theme.colors.shadow)};
  align-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  padding: ${theme.spacings.giga} ${theme.spacings.giga} ${theme.spacings.tera};
  min-width: 480px;
  min-height: 260px;
  z-index: ${theme.zIndex.modal};
`;

const ModalContainer = styled('div')(modalContainerStyles);

const modalCloseBaseStyles = () => emotionCss`
  margin-left: auto;
`;

const ModalClose = styled('div')(modalCloseBaseStyles);

const iconWrapperBaseStyles = ({ theme }) => emotionCss`
  cursor: pointer;
  fill: ${theme.colors.n800};

  svg {
    width: ${theme.iconSizes.giga};
    height: ${theme.iconSizes.giga};
  }
`;

const IconWrapper = styled('div')(iconWrapperBaseStyles);

const modalContentBaseStyles = () => emotionCss``;

const ModalContent = styled('div')(modalContentBaseStyles);

const Modal = ({
  children,
  onClose,
  closeIsActive,
  contentLabel,
  theme,
  appElement,
  ...otherProps
}) => {
  ReactModal.setAppElement(appElement);

  const getClassValues = mapValues(styleFn =>
    styleFn({
      theme
    })
  );

  return (
    <ClassNames>
      {({ css }) => {
        const reactModalProps = {
          className: getClassValues(modalClassName(css)),
          overlayClassName: getClassValues(overlayClassName(css)),
          contentLabel,
          onRequestClose: onClose,
          closeTimeoutMS: TRANSITION_DURATION,
          onAfterOpen: () => noScroll.on(),
          onAfterClose: () => noScroll.off(),
          ...otherProps
        };

        return (
          <ReactModal {...reactModalProps}>
            <ModalContainer>
              {closeIsActive && (
                <ModalClose>
                  <IconWrapper>
                    <CloseIcon role="img" onClick={onClose} />
                  </IconWrapper>
                </ModalClose>
              )}
              <ModalContent>
                {isFunction(children) ? children() : children}
              </ModalContent>
            </ModalContainer>
          </ReactModal>
        );
      }}
    </ClassNames>
  );
};

export const APP_ELEMENT_PROP_TYPE = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.node
]);

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeIsActive: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  theme: themePropType.isRequired,
  contentLabel: PropTypes.string,
  appElement: APP_ELEMENT_PROP_TYPE
};

Modal.defaultProps = {
  contentLabel: 'Modal',
  appElement: DEFAULT_APP_ELEMENT
};

export default withTheme(Modal);
