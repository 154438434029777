import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';

import Text from 'chrome/Text';

const baseStyles = ({ theme }) => css`
  border-radius: ${theme.spacings.peta};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.n800};
  color: ${theme.colors.white};
  width: 30px;
  height: 30px;
  text-align: center;
  margin-right: ${theme.spacings.kilo};
  letter-spacing: -1px;
`;

const StyledCounter = styled('div')(baseStyles);

const Counter = ({ children, ...props }) => (
  <StyledCounter {...props}>
    <Text
      {...{
        as: 'span',
        noMargin: true,
        bold: true
      }}
    >
      {children}
    </Text>
  </StyledCounter>
);

Counter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Counter;
