import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { flexStack } from 'styling/helpers';
import Feedback from 'feedback/FeedbackBuilder';

const baseStyles = ({ theme }) => css`
  max-width: ${theme.elementSize.sidebar};
  height: 100%;
  margin: 0 auto;
  ${flexStack()}
`;

const Container = styled('div')(baseStyles);

const Sidebar = () => (
  <Container>
    <Feedback />
  </Container>
);

export default Sidebar;
