import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import ui from 'ui/reducer';
import documents from 'documents/reducer';
import editor from 'editor/reducer';
import feedback from 'feedback/reducer';
import generate from 'generate/reducer';

const config = (state = {}) => state;

const rootReducer = history =>
  combineReducers({
    config,
    router: connectRouter(history),
    ui,
    documents,
    editor,
    feedback,
    generate
  });

export default rootReducer;
