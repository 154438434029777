import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { includes } from 'lodash/fp';

import { positionPropType, childrenPropType } from 'lib/shared-prop-types';
import { shadowSingle, textKilo, textBit } from 'styling/helpers';

// prettier-ignore
import { 
  CENTER, TOP, RIGHT, BOTTOM, LEFT, 
  BIT, BYTE,
  ALERT
} from 'lib/constants';

const baseStyles = ({ theme }) => css`
  width: 100%;
  max-width: 480px;
  background-color: ${theme.colors.n800};
  color: ${theme.colors.white};
  border-radius: ${theme.borderRadius.mega};
  position: absolute;
  z-index: ${theme.zIndex.tooltip};
  transition: opacity 0.3s;

  ${shadowSingle({ theme })};

  font-weight: ${theme.fontWeight.regular};
  text-align: left;

  &::after {
    display: block;
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border: ${theme.spacings.byte} solid transparent;
  }
`;

const positionMap = {
  [TOP]: 'bottom',
  [RIGHT]: 'left',
  [BOTTOM]: 'top',
  [LEFT]: 'right'
};

const getPositionStyles = ({ theme, position }) => {
  const absolutePosition = positionMap[position];
  return `
    ${absolutePosition}: 100%; ${'' /* Fallback  */}
    ${absolutePosition}: calc(100% + ${theme.spacings.byte});

    &::after {
      ${position}: 100%;
      border-${position}-color: ${theme.colors.n800};
    }
  `;
};

const getAlignmentStyles = ({ theme, position, align }) => {
  const isHorizontal = includes(position, [TOP, BOTTOM]);

  if (isHorizontal && includes(align, [TOP, BOTTOM, CENTER])) {
    return `
      left: 50%;
      transform: translateX(-50%);

      &::after {
        left: 50%;
        transform: translateX(-50%);
      }
    `;
  }

  if (!isHorizontal && includes(align, [LEFT, RIGHT, CENTER])) {
    return `
      top: 50%;
      transform: translateY(-50%);

      &::after {
        top: 50%;
        transform: translateY(-50%);
      }
    `;
  }

  const absolutePosition = positionMap[align];

  return `
    ${absolutePosition}: 50%;
    ${absolutePosition}: calc(50% - (${theme.spacings.mega} + ${theme.spacings.bit}));

    &::after {
      ${absolutePosition}: ${theme.spacings.kilo};
    }
  `;
};

const positionAndAlignStyles = ({ theme, position, align }) => css`
  ${getAlignmentStyles({ theme, position, align })};
  ${getPositionStyles({ theme, position })};
`;

const sizeStyles = ({ theme, size }) => {
  const isSmall = includes(size, [BIT, BYTE]);

  if (isSmall) {
    return `
      ${textBit({ theme })};
      min-width: 120px;
      padding: ${theme.spacings.byte} ${theme.spacings.byte};
    `;
  }

  return `
    min-width: 240px;
    padding: ${theme.spacings.byte} ${theme.spacings.kilo};
    ${textKilo({ theme })};
  `;
};

const appearanceStyles = ({ theme, appearance, position }) => {
  const isAlert = includes(appearance, [ALERT]);

  if (isAlert) {
    return `
      color: ${theme.colors.r700};
      background: ${theme.colors.r100};

      &::after {
        border-${position}-color: ${theme.colors.r100};
      }
    `;
  }

  return `
    background-color: ${theme.colors.n800};
    color: ${theme.colors.white};
   
    &::after {
      border-${position}-color: ${theme.colors.n800};
    }
  `;
};

const Tooltip = styled('div')`
  ${baseStyles};
  ${positionAndAlignStyles};
  ${sizeStyles};
  ${appearanceStyles};
`;

Tooltip.CENTER = CENTER;
Tooltip.TOP = TOP;
Tooltip.RIGHT = RIGHT;
Tooltip.BOTTOM = BOTTOM;
Tooltip.LEFT = LEFT;

Tooltip.propTypes = {
  children: childrenPropType.isRequired,
  position: positionPropType,
  size: PropTypes.string,
  appearance: PropTypes.string,
  align: PropTypes.oneOf([
    Tooltip.TOP,
    Tooltip.RIGHT,
    Tooltip.BOTTOM,
    Tooltip.LEFT,
    Tooltip.CENTER
  ])
};

Tooltip.defaultProps = {
  position: Tooltip.RIGHT,
  align: Tooltip.CENTER
};

export default Tooltip;
