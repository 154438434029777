import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import CircleNumber from 'chrome/CircleNumber';
import Text from 'chrome/Text';

const baseStyles = ({ theme }) => css`
  position: relative;
  display: flex;
  padding-left: ${theme.spacings.peta};
`;

const Container = styled('div')(baseStyles);

const List = ({ items }) =>
  items.map(({ number, text, hasNumber }, index) => (
    <Container key={index}>
      {hasNumber && <CircleNumber number={number} />}
      <Text as={'p'} size={Text.KILO}>
        {text}
      </Text>
    </Container>
  ));

List.propTypes = {
  items: PropTypes.array
};

export default List;
