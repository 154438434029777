import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { setActiveAccordion } from 'ui/actions';
import { isBackspaceKey } from 'lib/dom';
import {
  getHashcode,
  arraysAreEqual,
  getParagraphNodes,
  cleanHtml
} from 'lib/utils';
import {
  formatHtmlOnBlur,
  clickIsSpan,
  getSelectionCaret,
  jumpToCaretPosition
} from './utils';
import {
  setFormFieldValue,
  setFormHistory,
  selectWordToMakeActive
} from './actions';

import EditorWord from './EditorWord';

const baseContainerStyles = () => css`
  outline: 0;
  min-height: 50px;
`;

const StyledEditor = styled('div')(baseContainerStyles);

const Paragraph = styled('p')();

class EditorInput extends Component {
  el = React.createRef();

  getEditorRef = () => this.el.current;

  componentDidUpdate = prevProps => {
    const { id, replacedWords, handleFormHistory } = this.props;

    if (
      prevProps.replacedWords &&
      !arraysAreEqual(prevProps.replacedWords, replacedWords)
    ) {
      if (this.getInnerHtml() === '<p></p>') {
        return;
      }

      handleFormHistory({
        id,
        editorHtml: this.getInnerHtml()
      });
    }
  };

  getInnerHtml = () => {
    const ref = this.getEditorRef();
    return ref && ref.innerHTML;
  };

  getInnerText = () => {
    const ref = this.getEditorRef();
    return ref && ref.textContent;
  };

  setInnerHtml = () => {
    const ref = this.getEditorRef();
    ref.innerHTML = '<p></p>';
  };

  handleBlur = editorHtml => {
    const { id, handleEditorBlur } = this.props;
    const html = formatHtmlOnBlur(editorHtml);

    return handleEditorBlur({
      id,
      editorHtml: html
    });
  };

  handleKeyUp = event => {
    const hasContent = !!this.getInnerText().length;

    if (isBackspaceKey(event) && !hasContent) {
      this.setInnerHtml();
    }
  };

  handleClick = () => {
    const element = this.getEditorRef();
    const hasContent = !!this.getInnerText().length;

    if (!hasContent) {
      return;
    }

    const selection = window.getSelection();
    const { focusNode } = selection;
    const { parentNode } = focusNode;

    if (clickIsSpan(parentNode)) {
      const caretPosition = getSelectionCaret(element);
      const range = jumpToCaretPosition(element.parentNode, {
        count: caretPosition + 1
      });

      if (range) {
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  };

  createEditorHtml = () => {
    const {
      editorText,
      wordDict,
      byFindWordId,
      activeWord,
      replacedWords,
      handleWordClick
    } = this.props;

    if (!wordDict) {
      return editorText.map((paragraph, key) => (
        <Paragraph key={key}>{paragraph}</Paragraph>
      ));
    }

    return wordDict.map((paragraph, key) => {
      const partial = Object.keys(paragraph);

      return (
        <Paragraph key={key}>
          {partial.map(wordId => {
            const item = paragraph[wordId];
            const wordUid = getHashcode(item);
            const word = byFindWordId[wordUid] || null;

            if (!word) {
              return item;
            }

            const { id, uid, text, type, replacement } = word;

            let wordText;
            let isReplaced = false;

            if (replacedWords.includes(uid)) {
              wordText = replacement;
              isReplaced = true;
            } else {
              wordText = text;
            }

            const isActive = id === activeWord;
            const isReplacement = type === 'replacement';
            const isSuggestion = type === 'suggestion';

            return (
              <EditorWord
                key={wordId}
                {...{
                  id,
                  uid,
                  wordText,
                  isReplacement,
                  isSuggestion,
                  isReplaced,
                  isActive,
                  activeWord,
                  onSelect: handleWordClick
                }}
              />
            );
          })}
        </Paragraph>
      );
    });
  };

  render = () => {
    const { isSubmitting, id } = this.props;
    const html = this.createEditorHtml();

    return isSubmitting ? (
      <StyledEditor>{html}</StyledEditor>
    ) : (
      <StyledEditor
        key={id}
        ref={this.el}
        contentEditable={true}
        suppressContentEditableWarning={true}
        spellCheck={false}
        onBlur={({ target }) => this.handleBlur(target.innerHTML)}
        onClick={this.handleClick}
        onKeyUp={this.handleKeyUp}
      >
        {!html.length && <p></p>}
        {html}
      </StyledEditor>
    );
  };
}

EditorInput.propTypes = {
  id: PropTypes.string,
  editorText: PropTypes.array,
  isSubmitted: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  wordDict: PropTypes.array,
  replacedWords: PropTypes.array,
  byFindWordId: PropTypes.object,
  activeWord: PropTypes.string,
  replacedWord: PropTypes.array,
  handleEditorBlur: PropTypes.func,
  handleWordClick: PropTypes.func,
  handleFormHistory: PropTypes.func
};

EditorInput.defaultProps = {
  editorText: [],
  isSubmitted: false,
  isReplacement: true,
  isSuggestion: false,
  isReplaced: false
};

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  const { editor, feedback } = state;
  const {
    byId,
    wordDictionary,
    isSubmitting,
    isSubmitted,
    activeWord
  } = editor;
  const { byFindWordId, replacedWords } = feedback;

  if (!isSubmitted) {
    return {
      isSubmitting,
      editorText: []
    };
  }

  const editorHtml = byId[id];
  const wordDict = wordDictionary[id];
  const editorText = getParagraphNodes(cleanHtml(editorHtml, ['p']));

  return {
    wordDict,
    byFindWordId,
    replacedWords,
    activeWord,
    isSubmitted,
    isSubmitting,
    editorText
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleEditorBlur: values => dispatch(setFormFieldValue(values)),
    handleWordClick: id => {
      dispatch(selectWordToMakeActive(id));
      dispatch(setActiveAccordion(id));
    },
    handleFormHistory: values => dispatch(setFormHistory(values))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditorInput);
