import React from 'react';

import Header from 'app/Header';
import Content from 'app/Content';
import Sidebar from 'app/Sidebar';
import Footer from 'app/Footer';

import Layout from 'layout/Layout';
import Col from 'layout/Col';

import Brand from 'chrome/Brand';
import PageTitle from 'chrome/PageTitle';
import Toolbar from 'chrome/Toolbar';

import UserButtons from 'interactions/UserButtons';
import EditorButtons from 'editor/EditorButtons';

const CoreLayout = () => (
  <Layout>
    <Header>
      <Layout.Wrapper>
        <Layout.Row>
          <Col span="6">
            <Brand />
          </Col>
          <Col span="6">
            <UserButtons />
          </Col>
        </Layout.Row>
      </Layout.Wrapper>
    </Header>
    <Layout.Body>
      <Layout.Wrapper>
        <Layout.Row>
          <Col span="12">
            <PageTitle />
          </Col>
        </Layout.Row>
        <Layout.Row alignTop>
          <Col span="6">
            <Content />
          </Col>
          <Col span="6">
            <Sidebar />
          </Col>
        </Layout.Row>
      </Layout.Wrapper>
    </Layout.Body>
    <Layout.Footer>
      <Layout.Wrapper>
        <Footer />
      </Layout.Wrapper>
    </Layout.Footer>
    <Toolbar>
      <Layout.Wrapper>
        <Layout.Row>
          <Col span="12">
            <EditorButtons />
          </Col>
        </Layout.Row>
      </Layout.Wrapper>
    </Toolbar>
  </Layout>
);

export default CoreLayout;
