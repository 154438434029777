import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';

const baseStyles = () => css`
  position: relative;
  width: 100%;
`;

const activeStyles = ({ isBottom, isActive }) =>
  !isBottom &&
  isActive &&
  css`
    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      pointer-events: none;
      width: 100%;
      height: 120px;
    }
  `;

const Container = styled('div')(baseStyles, activeStyles);

const baseScrollContentStyles = ({ theme }) => css`
  width: 100%;
  padding-top: ${theme.spacings.byte};
  padding-left: ${theme.spacings.bit};
  padding-right: ${theme.spacings.bit};
`;

const activeScrollContentStyles = ({ isActive }) =>
  isActive &&
  css`
    max-height: 390px;
    overflow: auto;
  `;

const ScrollContent = styled('div')(
  baseScrollContentStyles,
  activeScrollContentStyles
);

const ScrollBox = ({ children, ...props }) => {
  const scrollRef = useRef();
  const [isBottom, setIsBottom] = useState(false);

  const handleScroll = () => {
    const { current } = scrollRef;
    const topWithOffset = current.scrollTop + 5;
    const scrollPosition = current.scrollHeight - current.offsetHeight;

    if (topWithOffset > scrollPosition && scrollPosition > 5) {
      return setIsBottom(true);
    }

    return setIsBottom(false);
  };

  useEffect(() => handleScroll);

  return (
    <Container {...props} isBottom={isBottom} onScroll={handleScroll}>
      <ScrollContent ref={scrollRef} {...props}>
        {children}
      </ScrollContent>
    </Container>
  );
};

ScrollBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

ScrollBox.defaultProps = {};

export default ScrollBox;
