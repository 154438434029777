import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';

const baseStyles = ({ theme }) => css`
  display: flex;
  align-items: center;
  margin: ${theme.spacings.kilo} 0 ${theme.spacings.giga};
`;

const Replacement = styled('div')(baseStyles);

Replacement.propTypes = {
  children: PropTypes.node
};

export default Replacement;
