const createRoute = baseUrl => route => `${baseUrl}/${route}`;

export const getEndpoint = baseUrl => {
  const setEndpoint = createRoute(baseUrl);
  return {
    validate: setEndpoint('validate'),
    export: setEndpoint('export'),
    documents: setEndpoint('documents'),
    submissions: setEndpoint('submissions'),
    feedback: setEndpoint('feedback')
  };
};
