import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import isPropValid from '@emotion/is-prop-valid';

import { sizes } from 'styling/constants';

const { BIT, BYTE, KILO, MEGA, GIGA } = sizes;

const mobileSizeMap = {
  [BIT]: BIT,
  [BYTE]: BYTE,
  [KILO]: KILO,
  [MEGA]: MEGA,
  [GIGA]: GIGA
};

const baseStyles = ({ theme }) => css`
  font-weight: ${theme.fontWeight.regular};
  margin-top: 0;
  margin-bottom: ${theme.spacings.mega};
`;

const sizeStyles = ({ theme, size }) => {
  if (!size) {
    return null;
  }

  return css`
    font-size: ${theme.typography.text[mobileSizeMap[size]].fontSize};
    line-height: ${theme.typography.text[mobileSizeMap[size]].lineHeight};
  `;
};

const boldStyles = ({ theme, bold }) =>
  bold &&
  css`
    font-weight: ${theme.fontWeight.bold};
  `;

const italicStyles = ({ italic }) =>
  italic &&
  css`
    font-style: italic;
  `;

const strikeThroughStyles = ({ theme, strike }) =>
  strike &&
  css`
    text-decoration: line-through;
    text-decoration-color: ${theme.colors.r700};
  `;

const marginStyles = ({ noMargin }) =>
  noMargin &&
  css`
    margin-bottom: 0;
  `;

const upperStyles = ({ upper }) =>
  upper &&
  css`
    text-transform: uppercase;
  `;

export const StyledText = styled('p', {
  shouldForwardProp: prop => isPropValid(prop) && prop !== 'size'
})`
  ${baseStyles};
  ${sizeStyles};
  ${marginStyles};
  ${boldStyles};
  ${italicStyles};
  ${strikeThroughStyles};
  ${upperStyles};
`;

const Text = ({ as, ...restProps }) => <StyledText {...restProps} as={as} />;

Text.BIT = BIT;
Text.BYTE = BYTE;
Text.KILO = KILO;
Text.MEGA = MEGA;
Text.GIGA = GIGA;

Text.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  size: PropTypes.oneOf([Text.BIT, Text.BYTE, Text.KILO, Text.MEGA, Text.GIGA]),
  className: PropTypes.string,
  noMargin: PropTypes.bool,
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  strike: PropTypes.bool,
  as: PropTypes.string
};

Text.defaultProps = {
  as: 'p',
  size: Text.KILO,
  className: '',
  noMargin: false,
  bold: false,
  italic: false,
  children: null
};

export default Text;
