import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import PureSpinner from 'chrome/Spinner';

const centeredStyles = () => css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -43%);
`;

const Spinner = styled(PureSpinner)(centeredStyles);

const LoadingIcon = ({ isLoading }) => <Spinner active={isLoading} />;

LoadingIcon.propTypes = {
  isLoading: PropTypes.bool.isRequired
};

export default LoadingIcon;
