import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import NavMenu from 'chrome/NavMenu';

const Footer = ({ footerLinks }) => (
  <NavMenu>
    <NavMenu.List>
      {footerLinks.map(({ key, url, label }) => (
        <NavMenu.Item key={key} label={label} url={url} />
      ))}
    </NavMenu.List>
  </NavMenu>
);

Footer.propTypes = {
  footerLinks: PropTypes.array
};

const mapStateToProps = state => {
  const { ui } = state;
  const { footerLinks } = ui;

  return {
    footerLinks
  };
};

export default connect(mapStateToProps)(Footer);
