import React from 'react';
import PropTypes from 'prop-types';

import ModalTrigger from 'modal/ModalTrigger';
import DocumentsLearnMore from 'documents/DocumentsLearnMore';

const LearnMore = ({ btnText }) => (
  <ModalTrigger
    {...{
      btnText,
      extra: {
        isSmall: true
      }
    }}
  >
    <DocumentsLearnMore />
  </ModalTrigger>
);

LearnMore.propTypes = {
  btnText: PropTypes.string
};

LearnMore.defaultProps = {
  btnText: 'Learn More'
};

export default LearnMore;
