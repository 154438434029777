import { combineReducers } from 'redux';
import { removeArrayValue, getHashcode } from 'lib/utils';
import * as actions from './actionTypes';

const getByKey = ({ data, key }) =>
  data
    .map(item => item[key])
    .filter((value, i, arr) => arr.indexOf(value) === i);

const getWordsByFind = ({ data, property }) =>
  data.reduce((obj, item) => {
    const currentObj = obj;
    const key = item[property];

    if (key.length > 0) {
      key.forEach(k => {
        const word = k.trim();
        currentObj[getHashcode(word)] = {
          ...currentObj[word],
          id: `${item.themeKey}_${item.id}`,
          uid: getHashcode(word),
          text: word,
          replacement: item.replacement && item.replacement,
          type: item.type
        };
      });
    }

    return currentObj;
  }, {});

const getBySingleValue = ({ data }) =>
  data.reduce((arr, item) => {
    const newArr = arr;
    newArr[item.id] = {
      ...item,
      id: `${item.themeKey}_${item.id}`
    };
    return newArr;
  }, {});

const byId = (state = {}, action) => {
  switch (action.type) {
    case actions.SET_FEEDBACK:
      return getBySingleValue({
        data: action.resp
      });
    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case actions.SET_FEEDBACK:
      return getByKey({
        data: action.resp,
        key: 'id'
      });
    default:
      return state;
  }
};

const byFindWordId = (state = {}, action) => {
  switch (action.type) {
    case actions.SET_FEEDBACK:
      return getWordsByFind({
        data: action.resp,
        property: 'find'
      });
    default:
      return state;
  }
};

const allFindWordIds = (state = [], action) => {
  switch (action.type) {
    case actions.SET_FEEDBACK:
      return Object.keys(
        getWordsByFind({
          data: action.resp,
          property: 'find'
        })
      );
    default:
      return state;
  }
};

const dismissedIds = (state = [], action) => {
  switch (action.type) {
    case actions.SET_FEEDBACK_AS_DISMISSED:
      if (state.includes(action.id)) {
        return state;
      }
      return [...state, action.id];
    case actions.SET_FEEDBACK_AS_ENABLED:
      return [
        ...removeArrayValue({
          arr: state,
          remove: action.id
        })
      ];
    default:
      return state;
  }
};

const replacedWords = (state = [], action) => {
  switch (action.type) {
    case actions.SET_FEEDBACK_AS_REPLACED:
      return [...state, action.id];
    case actions.UNDO_FEEDBACK_AS_REPLACED:
      return [
        ...removeArrayValue({
          arr: state,
          remove: action.id
        })
      ];
    case actions.SET_FEEDBACK:
      return [];
    default:
      return state;
  }
};

const acceptedIds = (state = [], action) => {
  switch (action.type) {
    case actions.SET_FEEDBACK_AS_ACCEPTED:
      if (state.includes(action.id)) {
        return state;
      }
      return [...state, action.id];
    case actions.UNDO_FEEDBACK_AS_ACCEPTED:
      return [
        ...removeArrayValue({
          arr: state,
          remove: action.id
        })
      ];
    default:
      return state;
  }
};

export default combineReducers({
  byId,
  allIds,
  byFindWordId,
  allFindWordIds,
  dismissedIds,
  replacedWords,
  acceptedIds
});
