import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import Image from 'chrome/Image';
import { ReactComponent as HireNextLogo } from './assets/logo/collab-logo-v2.svg';

const BrandLogo = () => <HireNextLogo role="img" />;

const baseBrandContainertyles = css`
  max-width: 400px;
  width: 100%;
  padding: 10px;

  svg {
    display: block;
  }
`;

const BrandContainer = styled('div')(baseBrandContainertyles);

const Brand = props => (
  <BrandContainer>
    <BrandLogo>
      <Image {...props} />
    </BrandLogo>
  </BrandContainer>
);

Brand.prototypes = {
  src: PropTypes.string
};

export default Brand;
