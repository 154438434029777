import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import Label from 'chrome/NavMenuLabel';

const baseListItemStyles = ({ theme }) => css`
  padding: 0 ${theme.spacings.mega};
`;

const StyledListItem = styled('li')(baseListItemStyles);

const baseLinkStyles = ({ theme }) => css`
  text-decoration: underline;
  color: ${theme.colors.black};
  transition: color 0.1s ease-in;
`;

const secondaryLinkStyles = ({ secondary }) => secondary && css``;

const hoverLinkStyles = ({ theme, selected, disabled }) =>
  !disabled &&
  !selected &&
  css`
    &:hover {
      color: ${theme.colors.n700};
    }
  `;

const StyledLink = styled('a')(
  baseLinkStyles,
  hoverLinkStyles,
  secondaryLinkStyles
);

const NavMenuItem = ({
  label,
  url,
  secondary,
  visible,
  defaultIcon,
  selectedIcon,
  selected,
  disabled,
  onClick,
  ...rest
}) => (
  <StyledListItem>
    <StyledLink
      href={url}
      selected={selected}
      secondary={secondary}
      visible={visible}
      disabled={disabled}
      {...rest}
    >
      <Label secondary={secondary} visible={visible}>
        {label}
      </Label>
    </StyledLink>
  </StyledListItem>
);

NavMenuItem.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string,
  secondary: PropTypes.bool,
  visible: PropTypes.bool,
  defaultIcon: PropTypes.node,
  selectedIcon: PropTypes.node,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

NavMenuItem.defaultProps = {
  label: '',
  secondary: false,
  visible: true,
  defaultIcon: '',
  selectedIcon: '',
  selected: false,
  disabled: false,
  onClick: null
};

export default NavMenuItem;
