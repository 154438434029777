import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';

import Wrapper from './LayoutWrapper';
import Row from './LayoutRow';
import Header from './LayoutHeader';
import Body from './LayoutBody';
import Footer from './LayoutFooter';

const baseStyles = () => css`
  display: flex;
  flex-wrap: wrap;
`;

const Container = styled('div')(baseStyles);

const Layout = ({ children }) => <Container>{children}</Container>;

Layout.propTypes = {
  children: PropTypes.node
};

Layout.defaultProps = {
  children: ''
};

Layout.Wrapper = Wrapper;
Layout.Row = Row;
Layout.Header = Header;
Layout.Body = Body;
Layout.Footer = Footer;

export default Layout;
