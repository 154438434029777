import { createBrowserHistory } from 'history';
import { base as theme } from 'styling/themes';
import configureStore from './configureStore';

const getPreloadState = config => {
  return {
    config
  };
};

const preload = config => {
  const preloadState = getPreloadState(config);
  const history = createBrowserHistory();
  const store = configureStore(preloadState, history);

  return {
    store,
    history,
    theme
  };
};

export default preload;
