import { curry, compose } from 'lodash/fp';

export { curry, compose };

export { clamp, head, isEqual, isString, toPairs, values } from 'lodash/fp';

export const map = curry((iteratee, arr) => arr.map(iteratee));

export const mapValues = curry((iteratee, obj) =>
  Object.keys(obj).reduce(
    (acc, key) => ({ ...acc, [key]: iteratee(obj[key]) }),
    {}
  )
);
