import { combineReducers } from 'redux';
import * as actions from './actionTypes';

const isExporting = (state = true, action) => {
  switch (action.type) {
    case actions.EXPORT_DOCUMENT_START:
      return true;
    case actions.EXPORT_DOCUMENT_SUCCESS:
    case actions.EXPORT_DOCUMENT_FAILURE:
      return false;
    default:
      return state;
  }
};

const isExported = (state = false, action) => {
  switch (action.type) {
    case actions.EXPORT_DOCUMENT_START:
    case actions.SET_IS_EXPORTED:
      return false;
    case actions.EXPORT_DOCUMENT_SUCCESS:
    case actions.EXPORT_DOCUMENT_FAILURE:
      return true;
    default:
      return state;
  }
};

const exportedFile = (state = {}, action) => {
  switch (action.type) {
    case actions.SET_IS_EXPORTED:
    case actions.EXPORT_DOCUMENT_FAILURE:
      return null;
    case actions.EXPORT_DOCUMENT_SUCCESS:
      return action.resp;
    default:
      return state;
  }
};

const exportedFilename = (state = null, action) => {
  switch (action.type) {
    case actions.EXPORT_SET_FILENAME:
      return action.name;
    default:
      return state;
  }
};

export default combineReducers({
  isExporting,
  isExported,
  exportedFile,
  exportedFilename
});
