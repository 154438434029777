import { camelizeKeys } from 'humps';
import { getEndpoint } from 'lib/routes';

const headers = new Headers({
  'content-type': 'application/json'
});

export const getTextValidationResults = ({ apiUrl }, data) => {
  const endpoint = getEndpoint(apiUrl);

  return fetch(endpoint.validate, {
    method: 'POST',
    body: JSON.stringify(data),
    headers
  })
    .then(resp => resp.json())
    .then(resp => camelizeKeys(resp));
};
