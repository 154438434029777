import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { find, identity } from 'lodash/fp';

import Tooltip from 'ui/Tooltip';
import { ReactComponent as DismissIcon } from './assets/icons/error.svg';
import { ReactComponent as UndoReplaceIcon } from './assets/icons/undo.svg';
import { ReactComponent as UndoDismissIcon } from './assets/icons/dismiss.svg';
import { ReactComponent as ReplaceIcon } from './assets/icons/replace.svg';

const baseStyles = ({ theme }) => css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-width: 50px;
  padding: ${theme.spacings.mega} ${theme.spacings.mega};
  padding-left: ${theme.spacings.byte};
`;

const StyledActions = styled('div')(baseStyles);

const iconWrapperBaseStyles = ({ theme }) => css`
  cursor: pointer;
  transition: fill ${theme.transitions.default};
  fill: ${theme.colors.n500};
  position: relative;

  svg {
    display: block;
    height: 25px;
    width: 25px;
  }
`;

const iconWrapperDismissStyles = ({ theme, isActive, isDismissIcon }) =>
  !isActive &&
  isDismissIcon &&
  css`
    fill: ${theme.colors.r700};
  `;

const iconWrapperReplaceStyles = ({ theme, isActive, isReplaceIcon }) =>
  !isActive &&
  isReplaceIcon &&
  css`
    fill: ${theme.colors.g700};
  `;

const iconWrapperAcceptStyles = ({ theme, isActive, isAcceptIcon }) =>
  !isActive &&
  isAcceptIcon &&
  css`
    fill: ${theme.colors.g700};
  `;

const iconWrapperReplaceDismissedStyles = ({ isDismissed, isReplaceIcon }) =>
  isDismissed &&
  isReplaceIcon &&
  css`
    opacity: 0.4;
    pointer-events: none;
  `;

const iconWrapperReplacedDismissStyles = ({ isReplaced, isDismissIcon }) =>
  isReplaced &&
  isDismissIcon &&
  css`
    opacity: 0.4;
    pointer-events: none;
  `;

const iconWrapperAcceptedDismissStyles = ({ isAccepted, isDismissIcon }) =>
  isAccepted &&
  isDismissIcon &&
  css`
    opacity: 0.4;
    pointer-events: none;
  `;

const iconWrapperActiveStyles = ({ theme, isDismissIcon, isActive }) =>
  !isDismissIcon &&
  isActive &&
  css`
    fill: ${theme.colors.n800}!important;
  `;

const iconWrapperDismissActiveStyles = ({ theme, isDismissIcon, isActive }) =>
  isDismissIcon &&
  isActive &&
  css`
    path {
      fill: ${theme.colors.n800}!important;
    }
  `;

const iconWrapperAcceptDismissedStyles = ({ isDismissed, isAcceptIcon }) =>
  isDismissed &&
  isAcceptIcon &&
  css`
    opacity: 0.4;
    pointer-events: none;
  `;

const IconWrapper = styled('div')(
  iconWrapperBaseStyles,
  iconWrapperDismissStyles,
  iconWrapperReplaceStyles,
  iconWrapperAcceptStyles,
  iconWrapperReplaceDismissedStyles,
  iconWrapperReplacedDismissStyles,
  iconWrapperAcceptedDismissStyles,
  iconWrapperActiveStyles,
  iconWrapperDismissActiveStyles,
  iconWrapperAcceptDismissedStyles
);

const Icon = ({
  isDismissed,
  isReplaced,
  isAccepted,
  isReplaceIcon,
  isDismissIcon,
  isAcceptIcon,
  tooltipText,
  ...props
}) => {
  const [isActive, setIsActive] = useState(false);

  const icons = [
    !isDismissed && isDismissIcon && <DismissIcon role="img" />,
    isDismissed && !isReplaceIcon && !isAcceptIcon && (
      <UndoDismissIcon role="img" />
    ),

    !isReplaced && isReplaceIcon && <ReplaceIcon role="img" />,
    isReplaced && isReplaceIcon && !isAcceptIcon && (
      <UndoReplaceIcon role="img" />
    ),

    !isAccepted && isAcceptIcon && <ReplaceIcon role="img" />,
    isAccepted && !isDismissIcon && !isReplaceIcon && (
      <UndoReplaceIcon role="img" />
    )
  ];

  const icon = find(identity, icons);

  return (
    <IconWrapper
      {...{
        isActive,
        isDismissed,
        isReplaced,
        isAccepted,
        isReplaceIcon,
        isDismissIcon,
        isAcceptIcon,
        ...props
      }}
      onMouseEnter={() => setIsActive(!isActive)}
      onMouseLeave={() => setIsActive(!isActive)}
    >
      {icon || null}
      {isActive && (
        <Tooltip position="left" size="bit">
          {tooltipText}
        </Tooltip>
      )}
    </IconWrapper>
  );
};

const Actions = ({
  id,
  wordIds,
  type,
  findWord,
  replaceWord,
  iconList,
  isDismissed,
  isReplaced,
  isAccepted,
  dismissOnClick,
  replaceOnClick,
  acceptOnClick,
  undoDismissOnClick,
  undoReplaceOnClick,
  undoAcceptOnClick
}) => (
  <StyledActions>
    {iconList.map(({ icon, showFor, helpText, helpTextRevert }, key) => {
      const isDismissIcon = icon === 'dismiss';
      const isReplaceIcon = icon === 'replace';
      const isAcceptIcon = icon === 'accept';

      const tooltipText =
        (isDismissIcon && isDismissed) ||
        (isReplaceIcon && isReplaced) ||
        (isAcceptIcon && isAccepted)
          ? helpTextRevert
          : helpText;

      let onClick;
      if (isDismissIcon && !isDismissed) {
        onClick = () => dismissOnClick(id);
      } else if (isDismissIcon && isDismissed) {
        onClick = () => undoDismissOnClick(id);
      } else if (isReplaceIcon && !isReplaced) {
        onClick = () =>
          replaceOnClick(id, {
            id: wordIds[0],
            findWord,
            replaceWord
          });
      } else if (isReplaceIcon && isReplaced) {
        onClick = () =>
          undoReplaceOnClick(id, {
            id: wordIds[0],
            findWord,
            replaceWord
          });
      } else if (isAcceptIcon && !isAccepted) {
        onClick = () => acceptOnClick(id);
      } else if (isAcceptIcon && isAccepted) {
        onClick = () => undoAcceptOnClick(id);
      }

      return (
        showFor.includes(type) && (
          <Icon
            {...{
              key,
              tooltipText,
              isDismissed,
              isReplaced,
              isAccepted,
              isReplaceIcon,
              isDismissIcon,
              isAcceptIcon,
              onClick
            }}
          />
        )
      );
    })}
  </StyledActions>
);

Actions.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  iconList: PropTypes.array,
  isDismissed: PropTypes.bool,
  dismissOnClick: PropTypes.func,
  enableOnClick: PropTypes.func,
  replaceOnClick: PropTypes.func
};

Icon.propTypes = Actions.propTypes;

Actions.defaultProps = {
  isDismissed: false,
  isReplaced: false,
  isAccepted: false,
  iconList: [
    {
      icon: 'dismiss',
      showFor: ['suggestion'],
      helpText: 'Click here if you are not planning to make the change.',
      helpTextRevert: 'Click to enable this recommendation.'
    },
    {
      icon: 'dismiss',
      showFor: ['replacement'],
      helpText: 'Click here to reject.',
      helpTextRevert: 'Click to enable this recommendation.'
    },
    {
      icon: 'replace',
      showFor: ['replacement'],
      helpText: 'Click here to replace.',
      helpTextRevert: 'Click to undo this replacement.'
    },
    {
      icon: 'accept',
      showFor: ['suggestion'],
      helpText: 'Click here to accept.',
      helpTextRevert: 'Click to undo this accept.'
    }
  ]
};

export default Actions;
