import { parse } from 'node-html-parser';
import { getParagraphNodes, cleanHtml, splitStringBeforeWord } from 'lib/utils';

const ALLOWED_TAGS = ['p', 'span'];
const SPAN_TAG = ['span'];
const PARAGRAPH_TAG = ['p'];

const hasEmptyTags = html => html === '<p></p>';

const cleanByTag = (str, tag) => cleanHtml(str, tag);

const byUl = ({ tagName }) => tagName === 'ul';

const bySpan = str => cleanByTag(str, SPAN_TAG).split(/<\/?span>/);

const addHyphenPrefix = ({ text }) => `<p>- ${text}</p>`;

const childNodes = html => parse(html).childNodes;

const getListNodes = html => childNodes(html).find(byUl);

const getReplaceWords = ({ allIds, byId }) => allIds.map(id => byId[id].text);

const getByParagraph = html =>
  getParagraphNodes(cleanByTag(html, ALLOWED_TAGS)).map(bySpan);

const splitStringByWords = ({ str, words }) =>
  splitStringBeforeWord({
    str,
    words
  });

const buildArrayDict = paragraphs => {
  let index = 0;
  return paragraphs.map(paragraph =>
    paragraph.reduce((acc, curr) => {
      const result = acc;
      result[index] = curr;
      index += 1;
      return result;
    }, {})
  );
};

export const buildWordDictionary = ({
  byId,
  allIds,
  allFindWordIds,
  byFindWordId
}) => {
  if (!allFindWordIds.length) {
    return [];
  }

  const words = getReplaceWords({
    allIds: allFindWordIds,
    byId: byFindWordId
  });

  return allIds
    .map(id => {
      const fieldDict = buildArrayDict(
        splitStringByWords({
          str: getParagraphNodes(byId[id]),
          words
        })
      );

      return {
        [id]: fieldDict
      };
    })
    .reduce((arr, curr) => {
      return {
        ...arr,
        ...curr
      };
    }, {});
};

export const formatHtmlOnBlur = html => {
  const bulletList = getListNodes(html);

  if (!bulletList) {
    return html;
  }

  return bulletList.childNodes.map(addHyphenPrefix).join('');
};

export const formatUpdatedValues = html =>
  hasEmptyTags(html) ? [] : getByParagraph(html);

export const formatEditorHtml = html =>
  cleanHtml(html, !hasEmptyTags(html) ? PARAGRAPH_TAG : '');

export const clickIsSpan = ({ tagName }) => tagName === 'SPAN';

export const getSelectionCaret = element => {
  const editable = element;

  window.getSelection().collapseToEnd();

  const sel = window.getSelection();
  const range = sel.getRangeAt(0);

  const selectedNode =
    editable === range.startContainer.parentNode
      ? sel.anchorNode
      : range.startContainer.parentNode;

  if (!selectedNode) {
    return {
      caret: -1,
      line: -1
    };
  }

  range.setStart(editable.firstChild, 0);

  const content = window.getSelection().toString();
  const text = JSON.stringify(content);

  window.getSelection().collapseToEnd();

  return text.length - 2;
};

export const jumpToCaretPosition = (node, chars, range) => {
  const newChars = chars;
  let newRange = range;

  if (!range) {
    newRange = document.createRange();
    newRange.selectNode(node);
    newRange.setStart(node, 0);
  }

  if (newChars.count === 0) {
    newRange.setEnd(node, newChars.count);
  } else if (node && newChars.count > 0) {
    if (node.nodeType === Node.TEXT_NODE) {
      if (node.textContent.length < newChars.count) {
        newChars.count -= node.textContent.length;
      } else {
        newRange.setEnd(node, newChars.count);
        newChars.count = 0;
      }
    } else {
      for (let lp = 0; lp < node.childNodes.length; lp += 1) {
        newRange = jumpToCaretPosition(node.childNodes[lp], newChars, newRange);

        if (newChars.count === 0) {
          break;
        }
      }
    }
  }

  return newRange;
};
