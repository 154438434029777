import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { HashRouter as Router } from 'react-router-dom';

import App from '../App';

const Root = ({ store, history, theme }) => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Router>
        <App theme={theme} />
      </Router>
    </ConnectedRouter>
  </Provider>
);

Root.propTypes = {
  theme: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default Root;
