import React from 'react';
import PropTypes from 'prop-types';

import Button from 'chrome/Button';
import { ModalProvider, ModalConsumer } from 'modal/ModalProvider';
import EditorConfirm from 'editor/EditorConfirm';

const modalContent = ({ ...props }) => <EditorConfirm {...props} />;

const EditorSubmit = ({ btnText, isLoading, submitCount, onSelect }) => (
  <ModalProvider>
    <ModalConsumer>
      {({ setModal, closeModal, enableClose, disableClose }) => (
        <Button
          isLoading={isLoading}
          onClick={() =>
            !submitCount
              ? onSelect()
              : setModal({
                  children: () =>
                    modalContent({
                      closeModal,
                      enableClose,
                      disableClose,
                      onSelect
                    })
                })
          }
          showPrefix
        >
          {btnText}
        </Button>
      )}
    </ModalConsumer>
  </ModalProvider>
);

EditorSubmit.propTypes = {
  btnText: PropTypes.string,
  isLoading: PropTypes.bool,
  submitCount: PropTypes.number,
  onSelect: PropTypes.func
};

export default EditorSubmit;
