import sanitizeHtml from 'sanitize-html';
import { parse } from 'node-html-parser';
import { searchPattern } from './constants';

const afterWords = pattern => new RegExp(`(\\b${pattern}\\b)`, 'gi');
const wordPattern = words => words.join(searchPattern.wholeWord);
const patternToFind = words => afterWords(wordPattern(words));

export const arraysAreEqual = (a, b) => {
  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0; i < a.length; i += 1) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
};

export const splitStringBeforeWord = ({ str, words }) =>
  str.map(item => item.split(patternToFind(words)));

export const getHashcode = str =>
  Array.from(str).reduce(
    (s, c) => Math.abs(Math.imul(31, s) + c.charCodeAt(0)) | 0, // eslint-disable-line no-bitwise
    0
  );

export const removeArrayValue = ({ arr, remove }) =>
  arr.filter(value => value !== remove);

export const cleanHtml = (str, allowedTags = []) => {
  const result = str.replace(/&nbsp;/g, ' ').replace(/·/g, '-');

  return sanitizeHtml(result, {
    allowedTags,
    allowedAttributes: {}
  });
};

export const getParagraphNodes = html =>
  parse(html).childNodes.map(({ text }) => text);

const formatDateTwoDigits = value => `0${value}`.slice(-2);

export const getFormattedTime = () => {
  const today = new Date();

  const y = today.getFullYear();
  const m = today.getMonth() + 1;
  const d = today.getDate();
  const h = today.getHours();
  const mi = today.getMinutes();

  return `${y}_${formatDateTwoDigits(m)}_${formatDateTwoDigits(
    d
  )}_${formatDateTwoDigits(h)}_${mi}`;
};

export const urlToFile = (url, filename, type) =>
  fetch(url)
    .then(res => res.arrayBuffer())
    .then(
      buffer =>
        new File([buffer], filename, {
          type
        })
    );

export const isObjectEmpty = ({ allIds, byId }) => {
  const values = allIds.filter(id => {
    const field = byId[id];

    if (Array.isArray(field)) {
      return !!field.length;
    }

    return !!field;
  });

  return !values.length;
};
