import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { find, identity } from 'lodash/fp';

import Text from 'chrome/Text';

import { ReactComponent as ArrowIcon } from './assets/icons/arrow-toggle.svg';
import { ReactComponent as DismissedIcon } from './assets/icons/error.svg';
import { ReactComponent as ReplacedIcon } from './assets/icons/replace.svg';

const baseThemeSeparatorStyles = ({ theme }) => css`
  position: relative;
  margin: 0 ${theme.spacings.giga} 0 ${theme.spacings.mega};
  width: ${theme.spacings.byte};
  height: ${theme.spacings.byte};

  &::before {
    background-color: ${theme.colors.n500};
    display: block;
    width: ${theme.spacings.byte};
    height: ${theme.spacings.byte};
    border-radius: 50%;
    content: '';
    transform: scale(0.7);
  }
`;

const ThemeSeparator = styled('span')(baseThemeSeparatorStyles);

const baseTextWrapperStyles = ({ theme }) => css`
  display: flex;
  align-items: center;
  margin-left: ${theme.spacings.giga};
`;

const replacedTextWrapperStyles = ({ isReplaced }) =>
  isReplaced &&
  css`
    margin-left: 0;
  `;

const dismissedTextWrapperStyles = ({ isDismissed }) =>
  isDismissed &&
  css`
    margin-left: 0;
  `;

const acceptedTextWrapperStyles = ({ isAccepted }) =>
  isAccepted &&
  css`
    margin-left: 0;
  `;

const TextWrapper = styled('div')(
  baseTextWrapperStyles,
  replacedTextWrapperStyles,
  dismissedTextWrapperStyles,
  acceptedTextWrapperStyles
);

const baseTextContainerStyles = () => css``;

const narrowTextContainerStyles = ({ narrow, textWidth }) =>
  narrow &&
  css`
    max-width: ${textWidth};
  `;

const TextContainer = styled('div')(
  baseTextContainerStyles,
  narrowTextContainerStyles
);

const arrowIconWrapperBaseStyles = ({ theme }) => css`
  fill: ${theme.colors.n500};
  width: 25px;
  margin-right: ${theme.spacings.kilo};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
`;

const ArrowIconWrapper = styled('div')(arrowIconWrapperBaseStyles);

const arrowWrapperBaseStyles = ({ theme }) => css`
  fill: ${theme.colors.n500};
  display: flex;
  transform: scale(1.2);
`;

const arrowWrapperUpStyles = ({ up }) =>
  up &&
  css`
    transform: rotate(180deg) scale(1.2);
  `;

const ArrowWrapper = styled('div')(
  arrowWrapperBaseStyles,
  arrowWrapperUpStyles
);

const containerBaseStyles = ({ theme }) => css`
  color: ${theme.colors.black};
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  padding: ${theme.spacings.kilo} ${theme.spacings.mega};

  &::before {
    background-color: ${theme.colors.r700};
    position: absolute;
    top: 16px;
    width: ${theme.spacings.kilo};
    height: ${theme.spacings.kilo};
    border-radius: 50%;
    content: '';
    transform: scale(0.7);
  }
`;

const containerSuggestionStyles = ({ theme, isSuggestion }) =>
  isSuggestion &&
  css`
    &::before {
      background-color: ${theme.colors.o200};
    }
  `;

const containerDismissedStyles = ({ isSuggestion, isDismissed }) =>
  isSuggestion &&
  isDismissed &&
  css`
    &::before {
      background-color: transparent;
    }
  `;

const containerReplacedStyles = ({ isReplaced }) =>
  isReplaced &&
  css`
    &::before {
      background-color: transparent;
    }
  `;

const containerAcceptedStyles = ({ isAccepted }) =>
  isAccepted &&
  css`
    &::before {
      background-color: transparent;
    }
  `;

const Container = styled('div')(
  containerBaseStyles,
  containerSuggestionStyles,
  containerReplacedStyles,
  containerDismissedStyles,
  containerAcceptedStyles
);

const statusIconWrapperBaseStyles = ({ theme }) => css`
  cursor: pointer;
  transition: fill ${theme.transitions.default};
  fill: ${theme.colors.n500};
  position: relative;
  background-color: ${theme.colors.white};

  svg {
    display: block;
    height: 25px;
    width: 25px;
  }
`;

const statusIconWrapperDismissedStyles = ({ theme, isDismissed }) =>
  isDismissed &&
  css`
    fill: ${theme.colors.r700};
  `;

const statusIconWrapperReplacedStyles = ({ theme, isReplaced }) =>
  isReplaced &&
  css`
    fill: ${theme.colors.g700};
  `;

const statusIconWrapperAcceptedStyles = ({ theme, isAccepted }) =>
  isAccepted &&
  css`
    fill: ${theme.colors.g700};
  `;

const StatusIconWrapper = styled('div')(
  statusIconWrapperBaseStyles,
  statusIconWrapperDismissedStyles,
  statusIconWrapperReplacedStyles,
  statusIconWrapperAcceptedStyles
);

const ToggleIcons = () => (
  <ArrowIconWrapper>
    <ArrowWrapper up>
      <ArrowIcon role="img" />
    </ArrowWrapper>
    <ArrowWrapper>
      <ArrowIcon role="img" />
    </ArrowWrapper>
  </ArrowIconWrapper>
);

const StatusIcon = ({ isDismissed, isReplaced, isAccepted }) => {
  const icons = [
    isDismissed && <DismissedIcon role="img" />,
    isReplaced && <ReplacedIcon role="img" />,
    isAccepted && <ReplacedIcon role="img" />
  ];

  const icon = find(identity, icons);

  return (
    <StatusIconWrapper
      {...{
        isDismissed,
        isReplaced,
        isAccepted
      }}
    >
      {icon || null}
    </StatusIconWrapper>
  );
};

const StyledText = ({ text, narrow, textWidth, ...rest }) => (
  <TextContainer narrow={narrow} textWidth={textWidth}>
    <Text
      {...{
        ...rest
      }}
      noMargin
    >
      {text}
    </Text>
  </TextContainer>
);

const Header = ({
  showToggleIcon,
  title,
  text,
  isSuggestion,
  isReplaced,
  isDismissed,
  isAccepted,
  onSelect,
  ...props
}) => {
  const showStatusIcons = isReplaced || isDismissed || isAccepted;
  return (
    <Container
      {...{
        isSuggestion,
        isDismissed,
        isReplaced,
        isAccepted,
        onClick: onSelect
      }}
    >
      <TextWrapper
        {...{
          isDismissed,
          isReplaced,
          isAccepted
        }}
      >
        {showStatusIcons && (
          <ArrowIconWrapper>
            <StatusIcon
              {...{
                isDismissed,
                isReplaced,
                isAccepted
              }}
            />
          </ArrowIconWrapper>
        )}
        <StyledText
          {...{
            text: title,
            bold: true,
            ...props
          }}
        />
        {text && (
          <>
            <ThemeSeparator />
            <StyledText
              {...{
                text,
                ...props
              }}
            />
          </>
        )}
      </TextWrapper>
      {showToggleIcon && (
        <ArrowIconWrapper>
          <ToggleIcons />
        </ArrowIconWrapper>
      )}
    </Container>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  isSuggestion: PropTypes.bool,
  isReplaced: PropTypes.bool,
  isDismissed: PropTypes.bool,
  isAccepted: PropTypes.bool,
  onSelect: PropTypes.func,
  showToggleIcon: PropTypes.bool
};

StatusIcon.propTypes = Header.propTypes;
StyledText.propTypes = Header.propTypes;

Header.defaultProps = {
  as: 'span',
  size: Text.KILO,
  isReplaced: false,
  isDismissed: false
};

export default Header;
