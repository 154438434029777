import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';

const baseStyles = () => css`
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
`;

const List = styled('ul')(baseStyles);

List.propTypes = {
  children: PropTypes.node
};

export default List;
