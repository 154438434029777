import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';

import Text from 'chrome/Text';

const baseStyles = () => css``;

const Container = styled('div')(baseStyles);

const Content = ({ children, size, as }) => (
  <Container>
    <Text
      {...{
        as,
        size
      }}
    >
      {children}
    </Text>
  </Container>
);

Content.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  size: PropTypes.string,
  as: PropTypes.string
};

Content.defaultProps = {
  as: 'p',
  size: Text.KILO
};

export default Content;
