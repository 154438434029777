import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import Tooltip from 'ui/Tooltip';
import Generate from 'generate/Generate';
import EditorSubmit from './EditorSubmit';

import { handleFormSubmit, toggleValidationMessage } from './actions';

const baseStyles = () => css`
  display: flex;
  justify-content: flex-end;
  max-height: 100%;
  height: 100%;
  width: 100%;
  text-align: right;
`;

const Container = styled('div')(baseStyles);

const baseButtonItemStyles = ({ theme }) => css`
  position: relative;
  margin-left: ${theme.spacings.kilo};
`;

const ButtonItem = styled('div')(baseButtonItemStyles);

const EditorButtons = ({
  submitBtnText,
  exportBtnText,
  isSubmitted,
  isSubmitting,
  submitCount,
  emptyValidationMessage,
  isValidationMessageActive,
  handleSubmitClick
}) => {
  const onSelect = handleSubmitClick;
  const isLoading = isSubmitting;

  return (
    <Container>
      <ButtonItem>
        {isValidationMessageActive && (
          <Tooltip position="top" appearance="alert">
            {emptyValidationMessage}
          </Tooltip>
        )}
        <EditorSubmit
          {...{
            isLoading,
            submitCount,
            btnText: submitBtnText,
            onSelect
          }}
        />
      </ButtonItem>
      <ButtonItem>
        <Generate isDisabled={!isSubmitted} exportBtnText={exportBtnText} />
      </ButtonItem>
    </Container>
  );
};

EditorButtons.propTypes = {
  submitBtnText: PropTypes.string,
  exportBtnText: PropTypes.string,
  isSubmitted: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  submitCount: PropTypes.number,
  emptyValidationMessage: PropTypes.string,
  isValidationMessageActive: PropTypes.bool,
  handleSubmitClick: PropTypes.func
};

EditorButtons.defaultProps = {
  submitBtnText: 'Get My Recommendations',
  exportBtnText: 'Export To Word'
};

const mapStateToProps = state => {
  const { editor } = state;
  const {
    isSubmitting,
    submitCount,
    isSubmitted,
    isValidationMessageActive
  } = editor;

  const emptyValidationMessage =
    'Please complete at least one field in the form before submitting.';

  return {
    isSubmitting,
    isSubmitted,
    submitCount,
    isValidationMessageActive,
    emptyValidationMessage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleSubmitClick: () => dispatch(handleFormSubmit()),
    toggleValidationMessage: values => dispatch(toggleValidationMessage(values))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditorButtons);
