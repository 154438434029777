import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { transparentize } from 'polished';

import { flexCenter, fixTop } from 'styling/helpers';

const baseStyles = ({ theme }) => css`
  background-color: ${theme.colors.bg100};
  box-shadow: 0 1px 7px 0 ${transparentize(0.6, theme.colors.shadow)};
  min-height: ${theme.elementSize.header};
  z-index: ${theme.zIndex.header};
  ${flexCenter()}
  ${fixTop()}
`;

const Container = styled('div')(baseStyles);

const Header = ({ children }) => <Container>{children}</Container>;

Header.propTypes = {
  title: PropTypes.string,
  mobileOnly: PropTypes.bool,
  children: PropTypes.node
};

Header.defaultProps = {
  children: ''
};

export default Header;
