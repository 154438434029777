import * as actions from './actionTypes';

export const setTooltipOpen = id => dispatch =>
  dispatch(actions.setTooltipToOpen(id));

export const setAccordionOpen = id => dispatch =>
  dispatch(actions.setAccordionToOpen(id));

export const setActiveAccordion = id => dispatch =>
  dispatch(actions.setAccordionToOpen(id));

export const setAccordionToggleComplete = id => dispatch =>
  dispatch(actions.setAccordionToggleComplete(id));
