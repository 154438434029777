import { combineReducers } from 'redux';
import * as actions from './actionTypes';

const initialState = {
  footerLinks: [
    {
      key: 'charityVillage',
      label: 'CharityVillage',
      url: 'https://charityvillage.com/'
    },
    {
      key: 'terms',
      label: 'Terms and Conditions',
      url: 'https://charityvillage.com/terms/'
    },
    {
      key: 'contactUs',
      label: 'Contact Us',
      url: 'https://charityvillage.com/contact-us/'
    }
  ]
};

const footerLinks = (state = initialState.footerLinks) => state;

const accordionOpenId = (state = null, action) => {
  switch (action.type) {
    case actions.SET_ACCORDION_OPEN:
      if (action.id === state) {
        return null;
      }
      return action.id || null;
    default:
      return state;
  }
};

const accordionIsOpen = (state = false, action) => {
  switch (action.type) {
    case actions.SET_ACCORDION_TOGGLE_COMPLETE:
      return true;
    case actions.SET_ACCORDION_OPEN:
      return false;
    default:
      return state;
  }
};

const tooltipOpenId = (state = null, action) => {
  switch (action.type) {
    case actions.SET_TOOLTIP_OPEN:
      return action.id;
    default:
      return state;
  }
};

export default combineReducers({
  footerLinks,
  accordionOpenId,
  accordionIsOpen,
  tooltipOpenId
});
