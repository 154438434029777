import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Documents from 'documents/Documents';

const DocumentsExport = ({ isFetching, documentList }) => (
  <Documents isFetching={isFetching} documentList={documentList} />
);

DocumentsExport.propTypes = {
  isFetching: PropTypes.bool,
  documentList: PropTypes.array
};

const mapStateToProps = state => {
  const { generate } = state;
  const { isExporting, exportedFile, exportedFilename } = generate;
  const isFetching = isExporting;

  const documentList = [
    {
      id: 'learnMore',
      text: 'Please click the button below to download the Word document.',
      buttonText: 'Download Word Document',
      isWordDoc: true,
      url: exportedFile,
      downloadName: exportedFilename
    }
  ];

  return {
    isFetching,
    documentList
  };
};

export default connect(mapStateToProps)(DocumentsExport);
