import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { flexCenter, textMega } from 'styling/helpers';
import LoadingIcon from 'chrome/LoadingIcon';

const baseStyles = ({ theme }) =>
  css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.btnDefault};
    transition: background-color ${theme.transitions.default};
    cursor: pointer;
    border: 0;
    border-radius: ${theme.borderRadius.giga};
    padding: 0 ${theme.spacings.mega};
    min-height: 34px;
    position: relative;
    text-decoration: none;
    ${textMega({ theme })}
    ${flexCenter()}

    &:focus,
    &:active {
      outline: none;
    }
  `;

const disabledStyles = ({ theme, isDisabled }) =>
  isDisabled &&
  css`
    background-color: ${theme.colors.n500};
    pointer-events: none;
  `;

const loadingStyles = ({ isLoading }) =>
  isLoading &&
  css`
    pointer-events: none;
  `;

const activeStyles = ({ theme, isActive }) =>
  isActive &&
  css`
    background-color: ${theme.colors.o300};
  `;

const smallStyles = ({ theme, isSmall }) =>
  isSmall &&
  css`
    font-size: ${theme.typography.text.bit.fontSize};
    font-weight: ${theme.fontWeight.medium};
    min-height: 25px;
    border-radius: ${theme.borderRadius.giga};
  `;

const invertStyles = ({ theme, isInvert }) =>
  isInvert &&
  css`
    color: ${theme.colors.n800};
    font-weight: ${theme.fontWeight.bold};
    background-color: ${theme.colors.y500};
  `;

const invertActiveStyles = ({ theme, isActive, isInvert }) =>
  isActive &&
  isInvert &&
  css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.n800};
  `;

const textWrapperStyles = ({ theme }) => css`
  display: inline-block;
  opacity: 1;
  transform: translate(0, 0);
  transition: opacity ${theme.transitions.default},
    transform ${theme.transitions.default};
`;

const textWrapperLoadingStyles = ({ isLoading }) =>
  isLoading &&
  css`
    opacity: 0;
    transform: translate(0, 100%);
  `;

const TextWrapper = styled.span`
  ${textWrapperStyles};
  ${textWrapperLoadingStyles};
`;

const StyledButton = styled('button')(
  baseStyles,
  disabledStyles,
  loadingStyles,
  activeStyles,
  smallStyles,
  invertStyles,
  invertActiveStyles
);

const Button = ({
  children,
  showPrefix,
  isDisabled,
  isLoading,
  active,
  ...props
}) => {
  const [isActive, setIsActive] = useState(active);
  return (
    <StyledButton
      {...{
        ...props,
        isDisabled,
        isLoading,
        isActive,
        onMouseEnter: () => setIsActive(!isActive),
        onMouseLeave: () => setIsActive(!isActive)
      }}
    >
      {showPrefix && <LoadingIcon isLoading={isLoading} />}
      <TextWrapper isLoading={isLoading}>{children}</TextWrapper>
    </StyledButton>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  showPrefix: PropTypes.bool,
  text: PropTypes.string,
  isSmall: PropTypes.bool,
  isInvert: PropTypes.bool,
  isDisabled: PropTypes.bool,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  active: PropTypes.bool
};

Button.defaultProps = {
  isSmall: false,
  isInvert: false,
  isDisabled: false,
  active: false
};

export default Button;
