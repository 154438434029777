import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

const baseStyles = ({ theme }) => css`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacings.giga};
`;

const Headerwrapper = styled('div')(baseStyles);

Headerwrapper.propTypes = {
  children: PropTypes.node
};

export default Headerwrapper;
