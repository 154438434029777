import { createMediaQueries } from 'styling/helpers';

const white = '#FFFFFF';
const black = '#0A0A0A';
const gradientLight = 'rgba(255, 255, 255, 0)';
const gradientDark = 'rgba(255, 255, 255, 1)';

const neutrals = {
  n100: '#FAFBFC',
  n200: '#EEF0F2',
  n300: '#D8DDE1',
  n500: '#9DA7B1',
  n700: '#1468a0',
  n800: '#244253',
  n900: '#212933'
};

const blues = {
  b100: '#EDF4FC',
  b200: '#DAEAFF',
  b300: '#AFD0FE',
  b400: '#7FB5FF',
  b500: '#3388FF',
  b700: '#1760CE',
  b900: '#003C8B'
};

const greens = {
  g100: '#E4EABB',
  g200: '#D4DB8F',
  g300: '#BED630',
  g500: '#62DE75',
  g700: '#11a683',
  g900: '#356560'
};

const violets = {
  v100: '#E9CFF2',
  v200: '#D7A9DC',
  v300: '#C781C9',
  v500: '#B54DB3',
  v700: '#8928A2',
  v900: '#5F1D6B'
};

const oranges = {
  o100: '#EFD0BB',
  o200: '#F7B97C',
  o300: '#F7941D',
  o500: '#CE6C0B',
  o700: '#8E4503',
  o900: '#66391B'
};

const yellows = {
  y100: '#F2E9C7',
  y200: '#EDDD8E',
  y300: '#F6CC1B',
  y500: '#e4a82f',
  y700: '#AD7A14',
  y900: '#725514'
};

const reds = {
  r100: '#F4CBCB',
  r200: '#EDA2A2',
  r300: '#EA7A7A',
  r500: '#DB4D4D',
  r700: '#B22828',
  r900: '#7F1818'
};

const primary = {
  p100: blues.b100,
  p200: blues.b200,
  p300: blues.b300,
  p400: blues.b400,
  p500: blues.b500,
  p700: blues.b700,
  p900: blues.b900
};

const cvPrimary = {
  bg100: '#0FD1B9',
  bg200: '#0FD1B9',
  btnDefault: '#ff7e27',
  btnActive: '#e3e3e3'
};

const misc = {
  shadow: '#8189A9',
  bodyBg: neutrals.n100,
  bodyColor: black,
  danger: reds.r500,
  success: greens.g700,
  warning: yellows.y500
};

export const colors = {
  white,
  black,
  gradientLight,
  gradientDark,
  ...neutrals,
  ...blues,
  ...greens,
  ...yellows,
  ...reds,
  ...oranges,
  ...violets,
  ...primary,
  ...cvPrimary,
  ...misc
};

export const spacings = {
  atom: '2px',
  bit: '4px',
  byte: '8px',
  kilo: '12px',
  mega: '16px',
  giga: '24px',
  tera: '32px',
  peta: '40px',
  exa: '48px',
  zetta: '56px',
  Yotta: '87px'
};

export const iconSizes = {
  byte: '14px',
  kilo: '16px',
  mega: '24px',
  giga: '32px',
  zetta: '80px',
  yotta: '100px'
};

export const borderRadius = {
  kilo: '1px',
  mega: '4px',
  giga: '5px',
  tera: '9px'
};

export const borderWidth = {
  kilo: '1px',
  mega: '2px',
  giga: '3px'
};

export const typography = {
  headings: {
    kilo: {
      fontSize: '17px',
      lineHeight: '24px'
    },
    mega: {
      fontSize: '19px',
      lineHeight: '24px'
    },
    giga: {
      fontSize: '22px',
      lineHeight: '24px'
    },
    tera: {
      fontSize: '24px',
      lineHeight: '32px'
    },
    peta: {
      fontSize: '28px',
      lineHeight: '32px'
    },
    exa: {
      fontSize: '36px',
      lineHeight: '44px'
    },
    zetta: {
      fontSize: '42px',
      lineHeight: '48px'
    }
  },
  subHeadings: {
    kilo: {
      fontSize: '12px',
      lineHeight: '20px'
    },
    mega: {
      fontSize: '14px',
      lineHeight: '18px'
    }
  },
  text: {
    bit: {
      fontSize: '11px',
      lineHeight: '14px'
    },
    byte: {
      fontSize: '12px',
      lineHeight: '16px'
    },
    kilo: {
      fontSize: '14px',
      lineHeight: '20px'
    },
    mega: {
      fontSize: '16px',
      lineHeight: '26px'
    },
    giga: {
      fontSize: '18px',
      lineHeight: '28px'
    }
  }
};

export const fontStack = {
  default:
    // eslint-disable-next-line
    'Helvetica, Arial, sans-serif'
};

export const fontWeight = {
  regular: '400',
  medium: '600',
  bold: '800'
};

export const grid = {
  default: {
    priority: 0,
    breakpoint: 'default',
    cols: 12,
    maxWidth: '880px',
    gutter: spacings.mega
  },
  untilKilo: {
    priority: 1,
    breakpoint: 'untilKilo',
    cols: 12,
    maxWidth: '400px',
    gutter: spacings.byte
  },
  kilo: {
    priority: 2,
    breakpoint: 'kilo',
    cols: 12,
    maxWidth: '600px',
    gutter: spacings.mega
  },
  mega: {
    priority: 3,
    breakpoint: 'mega',
    cols: 12,
    maxWidth: '760px',
    gutter: spacings.giga
  },
  giga: {
    priority: 4,
    breakpoint: 'giga',
    cols: 12,
    maxWidth: '880px',
    gutter: spacings.giga
  },
  afterTera: {
    priority: 5,
    breakpoint: 'tera',
    cols: 12,
    maxWidth: '1200px',
    gutter: spacings.giga
  }
};

export const breakpoints = {
  untilKilo: '(max-width: 479px)',
  kilo: 480,
  kiloToMega: '(min-width: 480px) and (max-width: 767px)',
  mega: 768,
  untilMega: '(max-width: 767px)',
  megaToGiga: '(min-width: 768px) and (max-width: 959px)',
  giga: 960,
  gigaToTera: '(min-width: 960px) and (max-width: 1279px)',
  tera: 1280,
  afterTera: '(min-width: 1280px)'
};

export const mq = createMediaQueries(breakpoints);

export const transitions = {
  default: `200ms ease-in-out`,
  slow: `300ms ease-in-out`
};

export const zIndex = {
  default: 0,
  absolute: 1,
  drawer: 10,
  textbox: 20,
  popover: 30,
  tooltip: 300,
  header: 600,
  backdrop: 700,
  sidebar: 800,
  dimmar: 900,
  modal: 1000,
  footer: 500
};

export const elementSize = {
  header: '70px',
  content: '1200px',
  sidebar: '440px',
  toolbar: '60px'
};
