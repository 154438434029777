import { combineReducers } from 'redux';
import { cleanHtml } from 'lib/utils';
import {
  formatUpdatedValues,
  formatEditorHtml,
  buildWordDictionary
} from './utils';
import * as actions from './actionTypes';

const initialState = {
  editorList: [
    {
      label: 'Job Title',
      id: 'title',
      placeholderText: '',
      helpText: 'Use standard job titles for your industry.'
    },
    {
      label: 'Company Overview',
      id: 'introduction',
      placeholderText: '',
      helpText:
        'Tell people about your company.'
    },
    {
      label: 'Role Responsibilities',
      id: 'responsibilities',
      placeholderText: '',
      helpText:
        'Outline the responsibilities and activities associated with the role.'
    },
    {
      label: 'Qualifications and Skills',
      id: 'requirements',
      placeholderText: '',
      helpText:
        'List what you are looking for in an applicant.'
    },
    {
      label: 'Perks and Opportunities',
      id: 'perks',
      placeholderText:
        '',
      helpText:
        'Information could include details on flexible working hours, benefits, training opportunities, etc.'
    },
    {
      label: 'Is there any other information that candidates applying for this job should know?',
      id: 'inclusivity',
      placeholderText:
        '',
      helpText:
        'Add accommodation information or your organization’s inclusion statement.'
    }
  ],
  wordDict: {
    title: [],
    introduction: [],
    requirements: [],
    responsibilities: [],
    perks: [],
    inclusivity: []
  },
  byId: {
    title: '',
    introduction: '',
    requirements: '',
    responsibilities: '',
    perks: '',
    inclusivity: ''
  },
  allIds: [
    'title',
    'introduction',
    'requirements',
    'responsibilities',
    'perks',
    'inclusivity'
  ]
};

const fields = (state = initialState.editorList) => state;

const isSubmitting = (state = false, action) => {
  switch (action.type) {
    case actions.FORM_SUBMIT_START:
      return true;
    case actions.FORM_SUBMIT_FAILURE:
    case actions.FORM_SUBMIT_SUCCESS:
      return false;
    default:
      return state;
  }
};

const isSubmitted = (state = false, action) => {
  switch (action.type) {
    case actions.FORM_SUBMIT_SUCCESS:
    case actions.FORM_SUBMIT_FAILURE:
      return true;
    default:
      return state;
  }
};

const byId = (state = initialState.byId, action) => {
  switch (action.type) {
    case actions.SET_FORM_FIELD_VALUE:
    case actions.SET_FORM_HISTORY:
      return {
        ...state,
        [action.id]: formatEditorHtml(action.editorHtml)
      };
    default:
      return state;
  }
};

const byCleaned = (state = initialState.byId, action) => {
  switch (action.type) {
    case actions.SET_FORM_FIELD_VALUE:
    case actions.SET_FORM_HISTORY:
      return {
        ...state,
        [action.id]: cleanHtml(action.editorHtml)
      };
    default:
      return state;
  }
};

const allIds = (state = initialState.allIds, action) => {
  switch (action.type) {
    case actions.SET_FORM_FIELD_VALUE:
      return state;
    default:
      return state;
  }
};

const bySubmission = (state = [], action) => {
  switch (action.type) {
    case actions.SET_SUBMISSION_DATA:
      return [
        ...state,
        {
          ...action.data
        }
      ];
    default:
      return state;
  }
};

const updatedDataToSend = (state = initialState.wordDict, action) => {
  switch (action.type) {
    case actions.SET_FORM_HISTORY:
    case actions.SET_FORM_FIELD_VALUE:
      return {
        ...state,
        [action.id]: formatUpdatedValues(action.editorHtml)
      };
    case actions.FORM_SUBMIT_SUCCESS:
      return initialState.wordDict;

    default:
      return state;
  }
};

const wordDictionary = (state = initialState.wordDict, action) => {
  switch (action.type) {
    case actions.SET_WORD_DICTIONARY:
      return buildWordDictionary({
        ...action.data
      });
    case actions.CLEAR_FORM_HISTORY:
      return initialState.wordDict;
    default:
      return state;
  }
};

const focusedFormField = (state = null, action) => {
  switch (action.type) {
    case actions.SET_EDITOR_FOCUS_STATE:
      return action.id || null;
    default:
      return state;
  }
};

const isValidationMessageActive = (state = false, action) => {
  switch (action.type) {
    case actions.SET_VALIDATION_MESSAGE_VISIBILITY:
      return !state;
    default:
      return state;
  }
};

const submitCount = (state = 0, action) => {
  switch (action.type) {
    case actions.FORM_SUBMIT_SUCCESS:
      return state + 1;
    default:
      return state;
  }
};

const activeWord = (state = null, action) => {
  switch (action.type) {
    case actions.SET_ACTIVE_WORD_ID:
      if (action.id === state) {
        return null;
      }
      return action.id || null;
    default:
      return state;
  }
};

const submissionId = (state = null, action) => {
  switch (action.type) {
    case actions.SET_FORM_SUBMISSION_ID || null:
      return action.id;
    default:
      return state;
  }
};

export default combineReducers({
  fields,
  isSubmitted,
  isSubmitting,
  byId,
  allIds,
  byCleaned,
  bySubmission,
  submissionId,
  wordDictionary,
  updatedDataToSend,
  focusedFormField,
  isValidationMessageActive,
  submitCount,
  activeWord
});
