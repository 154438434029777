import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import Heading from 'chrome/Heading';
import LearnMore from 'interactions/LearnMore';

const containerBaseStyles = ({ theme }) => css`
  display: flex;
  width: 100%;
  margin: ${theme.spacings.tera} 0;
`;
const titleContainerbaseStyles = () => css`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const buttonContainerBaseStyles = ({ theme }) => css`
  font-size: 14px;
  margin-top: ${theme.spacings.mega};
`;

const TitleContainer = styled('div')(titleContainerbaseStyles);
const Buttoncontainer = styled('div')(buttonContainerBaseStyles);
const Container = styled('div')(containerBaseStyles);

const Content = ({ heading, subheading }) => (
  <Container>
    <TitleContainer>
      <Heading size={Heading.PETA} as={'h1'} noMarginBoth>
        {heading}
      </Heading>
      <Heading
        size={Heading.KILO}
        as={'h2'}
        subHeading={'subHeadingStyles'}
        noMarginBoth
      >
        {subheading}
      </Heading>
      <Buttoncontainer>
        <LearnMore />
      </Buttoncontainer>
    </TitleContainer>
  </Container>
);

Content.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string
};

Content.defaultProps = {
  heading: 'HireNext Job Posting Assessment',
  subheading: 'Better attract a diverse talent pool of young professionals.'
};

export default Content;
