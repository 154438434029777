import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import isPropValid from '@emotion/is-prop-valid';

import { sizes } from 'styling/constants';

const { KILO, MEGA, GIGA, TERA, PETA, EXA, ZETTA } = sizes;

const mobileSizeMap = {
  [KILO]: KILO,
  [MEGA]: MEGA,
  [GIGA]: GIGA,
  [TERA]: TERA,
  [PETA]: PETA,
  [EXA]: EXA,
  [ZETTA]: ZETTA
};

const baseStyles = ({ theme }) => css`
  font-weight: ${theme.fontWeight.bold};
  margin-bottom: ${theme.spacings.tera};
`;

const sizeStyles = ({ theme, size }) =>
  size &&
  css`
    font-size: ${theme.typography.headings[mobileSizeMap[size]].fontSize};
    line-height: ${theme.typography.headings[mobileSizeMap[size]].lineHeight};
  `;

const noMarginTopStyles = ({ noMarginTop }) =>
  noMarginTop &&
  css`
    margin-top: 0;
  `;

const noMarginBottomStyles = ({ noMarginBottom }) =>
  noMarginBottom &&
  css`
    margin-bottom: 0;
  `;

const noMarginBothStyles = ({ noMarginBoth }) =>
  noMarginBoth &&
  css`
    margin-top: 0;
    margin-bottom: 0;
  `;

const subHeadingStyles = ({ theme, subHeading }) =>
  subHeading &&
  css`
    color: ${theme.colors.n500};
  `;

const HeadingElement = styled('h2', {
  shouldForwardProp: prop => isPropValid(prop) && prop !== 'size'
})`
  ${baseStyles};
  ${sizeStyles};
  ${noMarginTopStyles};
  ${noMarginBottomStyles};
  ${noMarginBothStyles};
  ${subHeadingStyles};
`;

const Heading = ({ as, subHeading, ...restProps }) => (
  <HeadingElement {...restProps} subHeading={subHeading} as={as} />
);

Heading.KILO = KILO;
Heading.MEGA = MEGA;
Heading.GIGA = GIGA;
Heading.TERA = TERA;
Heading.PETA = PETA;
Heading.EXA = EXA;
Heading.ZETTA = ZETTA;

Heading.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  size: PropTypes.oneOf([
    Heading.KILO,
    Heading.MEGA,
    Heading.GIGA,
    Heading.TERA,
    Heading.PETA,
    Heading.EXA,
    Heading.ZETTA
  ]),
  className: PropTypes.string,
  subHeading: PropTypes.string,
  noMarginTop: PropTypes.bool,
  noMarginBottom: PropTypes.bool,
  noMarginBoth: PropTypes.bool,
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6'])
};

Heading.defaultProps = {
  as: 'h2',
  size: Heading.EXA,
  className: '',
  subHeading: '',
  children: null
};

export default Heading;
