import React from 'react';
import styled from '@emotion/styled';

import Editor from 'editor/Editor';

const Container = styled('div')();

const Content = () => (
  <Container>
    <Editor />
  </Container>
);

export default Content;
