import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { transparentize } from 'polished';

const baseHighlightStyles = ({ theme }) => css`
  border-bottom: ${theme.borderWidth.giga} solid ${theme.colors.r500};
  transition: background-color 0.2s linear, border-bottom-color 0.5s linear;
  contain: layout style;
  cursor: pointer;
  outline: 0;
`;

const activeSuggestionHighlightStyles = ({ theme, isActive, isSuggestion }) =>
  isActive &&
  isSuggestion &&
  css`
    background-color: ${transparentize(0.8, theme.colors.o200)};
  `;

const activeReplacementHighlightStyles = ({ theme, isActive, isReplacement }) =>
  isActive &&
  isReplacement &&
  css`
    background-color: ${transparentize(0.8, theme.colors.r500)};
  `;

const activeReplacedHighlightStyles = ({ theme, isActive, isReplaced }) =>
  isActive &&
  isReplaced &&
  css`
    background-color: ${transparentize(0.8, theme.colors.g500)};
  `;

const suggestionHighlightStyles = ({ theme, isSuggestion }) =>
  isSuggestion &&
  css`
    border-color: ${theme.colors.o200};
  `;

const replacementHighlightStyles = ({ theme, isReplacement }) =>
  isReplacement &&
  css`
    border-color: ${theme.colors.r500};
  `;

const replacedHighlightStyles = ({ theme, isReplaced }) =>
  isReplaced &&
  css`
    border-color: ${theme.colors.g500};
  `;

const StyledWord = styled('span')`
  ${baseHighlightStyles};
  ${activeSuggestionHighlightStyles};
  ${activeReplacementHighlightStyles};
  ${activeReplacedHighlightStyles};
  ${suggestionHighlightStyles};
  ${replacementHighlightStyles};
  ${replacedHighlightStyles};
`;

const Word = ({
  id,
  wordText,
  isReplacement,
  isSuggestion,
  isReplaced,
  isActive,
  onSelect
}) => (
  <StyledWord
    {...{
      isReplacement,
      isSuggestion,
      isReplaced,
      isActive,
      onClick: () => onSelect(id)
    }}
  >
    {wordText}
  </StyledWord>
);

Word.propTypes = {
  id: PropTypes.string,
  wordText: PropTypes.string,
  isReplacement: PropTypes.bool,
  isSuggestion: PropTypes.bool,
  isReplaced: PropTypes.bool,
  isActive: PropTypes.bool,
  onSelect: PropTypes.func
};

Word.defaultProps = {
  isReplacement: false,
  isSuggestion: false,
  isReplaced: false,
  isActive: false
};

export default Word;
