import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';

import Text from 'chrome/Text';
import FancyList from 'chrome/FancyList';

import { ReactComponent as EmptyTextGraphic } from './assets/graphics/empty-form.svg';

const baseStyles = () => css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 350px;
  text-align: center;
`;

const subHeadingContainerbaseStyles = () => css`
  text-align: left;
`;

const Container = styled('div')(baseStyles);

const SubHeadingContainer = styled('div')(subHeadingContainerbaseStyles);

const basePlaceholderImageStyles = () => css`
  max-width: 200px;
`;

const PlaceholderImage = styled('div')(basePlaceholderImageStyles);

const FeedbackEmpty = ({ heading, instructions }) => (
  <Container>
    <PlaceholderImage>
      <EmptyTextGraphic role="img" />
    </PlaceholderImage>
    <Text as={'p'} size={Text.GIGA} bold>
      {heading}
    </Text>
    <SubHeadingContainer>
      <FancyList items={instructions} />
    </SubHeadingContainer>
  </Container>
);

FeedbackEmpty.propTypes = {
  heading: PropTypes.string,
  instructions: PropTypes.array
};

FeedbackEmpty.defaultProps = {
  heading: 'It is as easy as 1, 2, 3…',
  instructions: [
    {
      hasNumber: true,
      number: 1,
      text:
        'Simply enter or paste content from your job posting into the text boxes provided.'
    },
    {
      hasNumber: true,
      number: 2,
      text:
        'Click Get My Recommendations to receive suggestions or recommended changes - and make the ones that work for you.'
    },
    {
      hasNumber: true,
      number: 3,
      text:
        'Export your new and improved job posting along with recommendations.'
    },
    {
      hasNumber: false,
      text:
        'To receive more specific, tailored recommendations, we encourage you to complete all sections or as many as on can on this form.'
    },
    {
      hasNumber: false,
      text: 'Start your journey to more youth-inclusive practices today.'
    }
  ]
};

export default FeedbackEmpty;
