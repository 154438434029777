import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { textGiga } from 'styling/helpers';

const baseStyles = ({ theme }) => css`
  display: flex;
  font-weight: ${theme.fontWeight.bold};
  margin-bottom: ${theme.spacings.byte};
  ${textGiga({ theme })};
`;

const StyledLabel = styled('div')`
  ${baseStyles};
`;

const Label = props => <StyledLabel {...props} />;

export default Label;
