import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { flexCenter, absTop } from 'styling/helpers';

const styledCircleNumber = ({ theme }) => css`
  width: 30px;
  height: 30px;
  background-color: ${theme.colors.n800};
  border-radius: 50%;
  color: ${theme.colors.white};
  font-size: ${theme.typography.text.mega.fontSize};
  font-weight: ${theme.fontWeight.bold};
  ${absTop()}
  ${flexCenter()}
`;

const CircleNumberWrapper = styled('span')(styledCircleNumber);

const CircleNumber = ({ number }) => (
  <CircleNumberWrapper>{number}</CircleNumberWrapper>
);

CircleNumber.propTypes = {
  number: PropTypes.number
};

CircleNumber.defaultProps = {
  number: '1'
};

export default CircleNumber;
