import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ThemeProvider } from 'emotion-theming';

import BaseStyles from 'chrome/BaseStyles';
import Layout from 'app/Layout';

const App = ({ theme, token }) => {
  useEffect(() => {
    const currentToken = localStorage.getItem('token');
    localStorage.setItem('token', token || currentToken || '');
  });

  return (
    <ThemeProvider theme={theme}>
      <BaseStyles />
      <Layout />
    </ThemeProvider>
  );
};

App.propTypes = {
  theme: PropTypes.object.isRequired,
  token: PropTypes.string
};

const mapStateToProps = state => {
  const { router } = state;
  const { query } = router.location;
  const { token } = query;

  return {
    token
  };
};

export default connect(mapStateToProps)(App);
