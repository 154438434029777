export const SET_FEEDBACK = 'SET_FEEDBACK';
export const setFeedback = resp => {
  return {
    type: SET_FEEDBACK,
    resp
  };
};

export const SET_FEEDBACK_AS_DISMISSED = 'SET_FEEDBACK_AS_DISMISSED';
export const setFeedbackAsDismissed = id => {
  return {
    type: SET_FEEDBACK_AS_DISMISSED,
    id
  };
};

export const SET_FEEDBACK_AS_ENABLED = 'SET_FEEDBACK_AS_ENABLED';
export const setFeedbackAsEnabled = id => {
  return {
    type: SET_FEEDBACK_AS_ENABLED,
    id
  };
};

export const SET_FEEDBACK_AS_REPLACED = 'SET_FEEDBACK_AS_REPLACED';
export const setFeedbackAsReplaced = data => {
  return {
    type: SET_FEEDBACK_AS_REPLACED,
    ...data
  };
};

export const SET_FEEDBACK_AS_ACCEPTED = 'SET_FEEDBACK_AS_ACCEPTED';
export const setFeedbackAsAccepted = id => {
  return {
    type: SET_FEEDBACK_AS_ACCEPTED,
    id
  };
};

export const UNDO_FEEDBACK_AS_REPLACED = 'UNDO_FEEDBACK_AS_REPLACED';
export const undoFeedbackAsReplaced = data => {
  return {
    type: UNDO_FEEDBACK_AS_REPLACED,
    ...data
  };
};

export const UNDO_FEEDBACK_AS_ACCEPTED = 'UNDO_FEEDBACK_AS_ACCEPTED';
export const undoFeedbackAsAccepted = id => {
  return {
    type: UNDO_FEEDBACK_AS_ACCEPTED,
    id
  };
};
