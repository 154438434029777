import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { transparentize } from 'polished';

import { flexCenter, fixBottom } from 'styling/helpers';

const baseStyles = ({ theme }) => css`
  background-color: ${theme.colors.white};
  box-shadow: 0 1px 12px 0 ${transparentize(0.6, theme.colors.shadow)};
  min-height: ${theme.elementSize.toolbar};
  padding: 0 ${theme.spacings.mega};
  z-index: ${theme.zIndex.footer};
  ${flexCenter()}
  ${fixBottom()}
`;

const Container = styled('div')(baseStyles);

const Toolbar = ({ children }) => <Container>{children}</Container>;

Toolbar.propTypes = {
  title: PropTypes.string,
  mobileOnly: PropTypes.bool,
  children: PropTypes.node
};

export default Toolbar;
