import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducer';

export default (initialState = {}, history) => {
  const middleware = [thunkMiddleware, routerMiddleware(history)];
  const enhancers = [];

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line no-underscore-dangle

  const store = createStore(
    rootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(...middleware), ...enhancers)
  );

  if (module.hot) {
    module.hot.accept('./reducer', () => {
      const reducers = require('./reducer').default; // eslint-disable-line global-require
      store.replaceReducer(reducers);
    });
  }

  return store;
};
