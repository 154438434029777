import React from 'react';
import PropTypes from 'prop-types';
import Label from 'chrome/Label';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

const baseLabelTextContainerStyles = ({ theme }) => css`
  margin-right: ${theme.spacings.byte};
  margin-top: ${theme.spacings.byte};
  font-weight: ${theme.fontWeight.bold};
`;

const baseHelpTextContainerStyles = ({ theme }) => css`
  margin-right: ${theme.spacings.byte};
  margin-bottom: ${theme.spacings.kilo};
  font-size: 15px;
`;

const LabelTextContainer = styled('div')(baseLabelTextContainerStyles);

const HelpTextContainer = styled('div')(baseHelpTextContainerStyles)

const EditorLabel = ({ id, label, helpText }) => (
<>
 <Label>
      <LabelTextContainer tabIndex={0}>
        {label}
      </LabelTextContainer>

    </Label>
          <HelpTextContainer>
          {helpText}
        </HelpTextContainer>
        </>
);

EditorLabel.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  helpText: PropTypes.string
};

export default EditorLabel;
