import { isObjectEmpty } from 'lib/utils';
import { generateDocument } from './api';
import {
  getExportFileUrl,
  buildExportDictionary,
  buildParagraphDictionary,
  getReplacements
} from './utils';
import * as actions from './actionTypes';

export const fetchDocument = () => (dispatch, getState) => {
  const { config, editor, feedback } = getState();
  const { allFindWordIds, byFindWordId } = feedback;
  const { allIds, bySubmission, updatedDataToSend, submissionId } = editor;

  dispatch(actions.exportDocumentStart());

  const originalHighlightedData = buildExportDictionary({
    allIds,
    byId: bySubmission[bySubmission.length - 1],
    allFindWordIds,
    byFindWordId
  });

  const originalNonHighlightedData = buildParagraphDictionary({
    allIds,
    byId: bySubmission[bySubmission.length - 1]
  });

  const noUpdatedValues = isObjectEmpty({
    byId: updatedDataToSend,
    allIds
  });

  const replacements = getReplacements({ allFindWordIds, byFindWordId });

  const updatedSection = allIds.reduce((acc, id) => {
    const field = updatedDataToSend[id];

    if (!field.length) {
      return {
        ...acc,
        [id]: originalNonHighlightedData[id]
      };
    }

    return {
      ...acc,
      [id]: field
    };
  }, {});

  const documentData = {
    text: {
      byId: {
        updated: {
          title: 'Job Posting',
          sections: updatedSection
        },
        original: {
          title: `${noUpdatedValues ? '' : 'Original '}Job Posting`,
          sections: originalHighlightedData
        }
      },
      allIds: noUpdatedValues ? ['original'] : ['updated', 'original']
    },
    recommendations: {
      byId: feedback.byId,
      allIds: feedback.allIds
    },
    replacements,
    submissionId,
    token: localStorage.getItem('token')
  };

  return generateDocument(config, documentData).then(
    resp =>
      getExportFileUrl(resp).then(file => {
        const fileReader = new FileReader();

        fileReader.onload = () => {
          const downloadUrl = URL.createObjectURL(file);
          dispatch(actions.exportSetFilename(file.name));
          return dispatch(actions.exportDocumentSuccess(downloadUrl));
        };

        fileReader.readAsText(file);
      }),
    err => dispatch(actions.exportDocumentFailure(err))
  );
};

export const setIsExported = () => dispatch =>
  dispatch(actions.setIsExported());
