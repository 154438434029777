import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

const baseStyles = ({ theme }) => css`
  font-size: ${theme.typography.subHeadings.mega.fontSize};
`;

const NavMenuLabel = styled.div(baseStyles);

NavMenuLabel.propTypes = {
  secondary: PropTypes.bool,
  visible: PropTypes.bool
};

export default NavMenuLabel;
