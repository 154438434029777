import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { getSpanStyles, getBreakPointStyles } from './utils';

const baseStyles = ({ theme, span }) => css`
  flex: 0 0 auto;

  ${getBreakPointStyles(theme)};
  ${getSpanStyles(theme, span)};
`;

const Col = styled('div')(baseStyles);

const sizingProp = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.number,
  PropTypes.string
]);

Col.propTypes = {
  span: sizingProp
};

Col.defaultProps = {
  span: '0'
};

export default Col;
