import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

const baseStyles = () => css`
  label: image;
  display: block;
  height: auto;
  max-height: 100%;
  width: 100%;
`;

const StyledImage = styled('img')(baseStyles);

const Image = props => <StyledImage {...props} />;

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

Image.defaultProps = {
  src: 'https://source.unsplash.com/random',
  alt: 'Alt Tag'
};

export default Image;
