import { css } from '@emotion/core';

import {
  isEqual,
  isString,
  clamp,
  toPairs,
  head,
  compose,
  curry,
  map,
  mapValues,
  values
} from 'lib/fp';

import { MIN_COL_SPAN, MAX_COL_WIDTH, DEFAULT_BREAKPOINT } from 'lib/constants';

export const isDefault = isEqual(DEFAULT_BREAKPOINT);

export const wrapStyles = (styles, breakpoint, theme) =>
  isDefault(breakpoint) ? css(styles) : `${theme.mq[breakpoint]} { ${styles} }`;

export const createSpanStyles = (grid, theme, span) => {
  if (!grid) {
    return null;
  }

  const { cols, breakpoint } = grid;
  const safeSpan = clamp(MIN_COL_SPAN, cols, span);

  const styles = `
    width: ${(MAX_COL_WIDTH / cols) * safeSpan}%;
  `;

  return wrapStyles(styles, breakpoint, theme);
};

const createBreakpointStyles = curry((theme, current) => {
  const config = theme.grid[current.breakpoint];

  if (!config) {
    return null;
  }

  const styles = `
    padding-left: calc(${config.gutter} / 2);
    padding-right: calc(${config.gutter} / 2);
  `;

  return wrapStyles(styles, current.breakpoint, theme);
});

export const getBreakPointStyles = theme =>
  compose(values, mapValues(createBreakpointStyles(theme)))(theme.grid);

export const sortByPriority = curry((grid, iteratee) =>
  iteratee.sort((a, b) => grid[head(a)].priority - grid[head(b)].priority)
);

export const mapBreakpoint = curry((fn, grid, theme, [key, value]) =>
  fn(grid[key], theme, value)
);

const composeBreakpoints = curry((fn, grid, theme, breakpoints) =>
  compose(
    map(mapBreakpoint(fn, grid, theme)),
    sortByPriority(grid),
    toPairs
  )(breakpoints)
);

export const getSpanStyles = ({ grid, ...theme }, span) =>
  isString(span)
    ? createSpanStyles(grid.default, theme, span)
    : composeBreakpoints(createSpanStyles, grid, theme, span);
