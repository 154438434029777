import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';

import Heading from 'chrome/Heading';

const baseStyles = () =>
  css`
    text-transform: capitalize;
  `;

const Container = styled('div')(baseStyles);

const Title = ({ children, size, as, ...props }) => (
  <Container>
    <Heading
      {...{
        as,
        size,
        noMarginBoth: true,
        ...props
      }}
    >
      {children}
    </Heading>
  </Container>
);

Title.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  text: PropTypes.string,
  size: PropTypes.string,
  as: PropTypes.string
};

Title.defaultProps = {
  as: 'h3',
  size: Heading.KILO
};

export default Title;
