import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';

const baseStyles = () => css`
  width: 100%;
`;

const Header = styled('div')(baseStyles);

Header.propTypes = {
  children: PropTypes.node
};

export default Header;
