import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';

const baseStyles = ({ theme }) => css`
  max-width: 350px;
  padding-left: ${theme.spacings.peta};
  padding-bottom: ${theme.spacings.byte};
`;

const Wrapper = styled('div')(baseStyles);

Wrapper.propTypes = {
  children: PropTypes.node
};

export default Wrapper;
