import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import IconWithText from 'chrome/IconWithText';
import Text from 'chrome/Text';

import { ReactComponent as PdfIcon } from './assets/icons/valid.svg';
import { submitForm } from './actions';

const baseStyles = () => css`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100%;
`;

const Container = styled('div')(baseStyles);

const iconWrapperBaseStyles = ({ theme }) => css`
  cursor: pointer;
  fill: ${theme.colors.n800};

  svg {
    width: ${theme.iconSizes.yotta};
    height: ${theme.iconSizes.yotta};
  }
`;

const IconWrapper = styled('div')(iconWrapperBaseStyles);

const EditorConfirm = ({ text, buttonText, closeModal, onSelect }) => (
  <Container>
    <IconWithText>
      <IconWithText.Icon>
        <IconWrapper>
          <PdfIcon />
        </IconWrapper>
      </IconWithText.Icon>
      <IconWithText.Text as={'p'} size={Text.GIGA}>
        {text}
      </IconWithText.Text>
      <IconWithText.Button
        onClick={() => {
          closeModal();
          onSelect();
        }}
      >
        {buttonText}
      </IconWithText.Button>
    </IconWithText>
  </Container>
);

EditorConfirm.propTypes = {
  text: PropTypes.string,
  buttonText: PropTypes.string,
  closeModal: PropTypes.func,
  onSelect: PropTypes.func
};

EditorConfirm.defaultProps = {
  text:
    'You have made changes to your job posting. Would you like to re-submit this job posting and receive new recommendations?',
  buttonText: 'Yes, Get New Recommendations'
};

const mapStateToProps = state => {
  const { editor } = state;
  const { isSubmitting } = editor;

  return {
    isSubmitting
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleFormValidateClick: () => dispatch(submitForm())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditorConfirm);
