import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';

const baseStyles = () => css`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;

const marginStyles = ({ theme, hasMargin }) =>
  hasMargin &&
  css`
    margin-bottom: ${theme.spacings.giga};
  `;

const alignStyles = ({ alignTop }) =>
  alignTop &&
  css`
    align-items: flex-start;
  `;

const Row = styled('div')(baseStyles, marginStyles, alignStyles);

Row.propTypes = {
  children: PropTypes.node
};

export default Row;
