import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { transparentize } from 'polished';

const baseStyles = ({ theme }) => css`
  background-color: ${theme.colors.white};
  box-shadow: 0 1px 7px 0 ${transparentize(0.75, theme.colors.shadow)};
  border-radius: ${theme.borderRadius.giga};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${theme.spacings.mega};
`;

const replacedStyles = ({ theme, isReplaced }) =>
  isReplaced &&
  css`
    &::before {
      background-color: ${theme.colors.g700};
    }
  `;

const Item = styled('li')(baseStyles, replacedStyles);

Item.propTypes = {
  children: PropTypes.node
};

export default Item;
