import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';

import Text from 'chrome/Text';

const baseStyles = ({ theme }) => css`
  position: relative;
  margin-right: ${theme.spacings.exa};

  &::after {
    font-family: 'Trebuchet MS', Helvetica, sans-serif;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    margin-left: 10px;
    font-size: 32px;
    color: ${theme.colors.n300};
    content: '\\2192';
  }
`;

const StyledWord = styled('div')(baseStyles);

const OldWord = ({ children, as, size }) => (
  <StyledWord>
    <Text {...{ as, size }} strike noMargin>
      {children}
    </Text>
  </StyledWord>
);

OldWord.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  as: PropTypes.string,
  size: PropTypes.string
};

OldWord.defaultProps = {
  as: 'span',
  size: Text.GIGA
};

export default OldWord;
