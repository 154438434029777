import * as actions from './actionTypes';
import { confirmFeedback } from './api';

export const setFeedbackAsDismissed = id => dispatch =>
  dispatch(actions.setFeedbackAsDismissed(id));

export const setFeedbackAsEnabled = id => dispatch =>
  dispatch(actions.setFeedbackAsEnabled(id));

export const setFeedbackAsReplaced = data => dispatch =>
  dispatch(actions.setFeedbackAsReplaced(data));

export const setFeedbackAsAccepted = id => dispatch =>
  dispatch(actions.setFeedbackAsAccepted(id));

export const undoFeedbackAsReplaced = data => dispatch =>
  dispatch(actions.undoFeedbackAsReplaced(data));

export const undoFeedbackAsAccepted = id => dispatch =>
  dispatch(actions.undoFeedbackAsAccepted(id));

export const setFeedback = resp => dispatch =>
  dispatch(actions.setFeedback(resp));

export const submitFeedback = (feedbackId, feedbackAction, feedbackType) => (dispatch, getState) => {
  const { config, editor, feedback } = getState();
  const { submissionId } = editor;
  const { byId } = feedback
  const feedbackObj = Object.values(byId).find( ({ id }) => id === feedbackId );

  return confirmFeedback(config, {
    submissionId,
    feedbackAction,
    feedbackType,
    token: localStorage.getItem('token'),
    ...feedbackObj
  })
};
