import { isObjectEmpty } from 'lib/utils';
import { setFeedback } from 'feedback/actions';
import { setIsExported } from 'generate/actions';
import { getTextValidationResults } from './api';
import * as actions from './actionTypes';

const buildWordDictionary = () => (dispatch, getState) => {
  const { editor, feedback } = getState();
  const { byId, allIds } = editor;
  const { allFindWordIds, byFindWordId } = feedback;

  dispatch(
    actions.setWordDictionary({
      byId,
      allIds,
      allFindWordIds,
      byFindWordId
    })
  );
};

export const submitForm = () => (dispatch, getState) => {
  const { config, editor } = getState();
  const { byId } = editor;

  dispatch(actions.clearFormHistory({}));
  dispatch(actions.formSubmitStart());
  dispatch(setIsExported());

  return getTextValidationResults(config, {
    data: byId,
    token: localStorage.getItem('token'),
    api: false,
  }).then(
    resp => {
      const { data, submissionId } = resp;
      const formattedData = JSON.parse(data);

      dispatch(actions.setSubmissionId(submissionId));
      dispatch(actions.formSubmitSuccess(formattedData));
      dispatch(actions.setSubmissionData(byId));

      dispatch(setFeedback(formattedData));
      dispatch(buildWordDictionary());
    },
    err => dispatch(actions.formSubmitFailure(err))
  );
};

export const toggleValidationMessage = () => dispatch =>
  dispatch(actions.setValidationMessageVisibility());

export const setFocusState = id => dispatch =>
  dispatch(actions.setFocusState(id));

export const selectWordToMakeActive = id => dispatch =>
  dispatch(actions.setActiveWordId(id));

export const setActiveEditorWord = id => dispatch =>
  dispatch(actions.setActiveWordId(id));

export const replaceWords = id => dispatch =>
  dispatch(actions.replaceWordsWithReplacement(id));

export const undoReplacedWords = id => dispatch =>
  dispatch(actions.undoReplacedWords(id));

export const setFormFieldValue = value => dispatch =>
  dispatch(actions.setFormFieldValue(value));

export const setFormValuesToUpdatedValues = values => dispatch =>
  dispatch(actions.setFormValuesToUpdatedValues(values));

export const setUpdatedFormFieldValue = data => dispatch =>
  dispatch(actions.setUpdatedFormFieldValue(data));

export const setFormHistory = data => dispatch =>
  dispatch(actions.setFormHistory(data));

export const storeFormFieldValue = value => dispatch =>
  dispatch(actions.storeFormFieldValue(value));

export const handleFormSubmit = () => (dispatch, getState) => {
  const { editor } = getState();
  const { byCleaned, allIds } = editor;

  const isValid = !isObjectEmpty({
    byId: byCleaned,
    allIds
  });

  if (isValid) {
    return dispatch(submitForm());
  }

  dispatch(toggleValidationMessage());

  return setTimeout(() => {
    dispatch(toggleValidationMessage());
  }, 3500);
};
