import { combineReducers } from 'redux';

const learnMore = (
  state = [
    {
      id: 'jobDescription',
      text: 'Sample Job Description (122 kb)',
      filename: 'Sample_Job_Description.pdf',
      buttonText: 'View',
      isLink: true,
      filepath: 'https://drive.google.com/file/d/1cISXWCLX_oZOU2dqCd25e1kwvfyJz6mX/view?usp=share_link'
    },
    {
      id: 'youthOpinions',
      text: 'Youth Opinions on Job Posting Barriers (74 kb)',
      filename: 'NPower_Survey_Results_For_Posting.pdf',
      buttonText: 'View',
      isLink: true,
      filepath: 'https://drive.google.com/file/d/15CMxlaSmaMS9g-zTQcFboL7YseTOaE9k/view?usp=share_link'
    },
    {
      id: 'userGuide',
      text: 'User Guide (1.2 mb)',
      filename: 'User_Guide.pdf',
      buttonText: 'View',
      isLink: true,
      filepath: 'https://drive.google.com/file/d/1rI3lRF0DrzHEcKi3VVMPH-Oxr8ITOyP5/view?usp=share_link'
    }
  ]
) => state;

export default combineReducers({
  learnMore
});
