import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';

const baseStyles = () => css`
  width: 100%;
`;

const dismissedStyles = ({ isDismissed }) =>
  isDismissed &&
  css`
    opacity: 0.5;
  `;

const replacedStyles = ({ isReplaced }) =>
  isReplaced &&
  css`
    opacity: 0.5;
  `;

const acceptedStyles = ({ isAccepted }) =>
  isAccepted &&
  css`
    opacity: 0.5;
  `;

const Row = styled('div')(
  baseStyles,
  dismissedStyles,
  replacedStyles,
  acceptedStyles
);

Row.propTypes = {
  children: PropTypes.node
};

export default Row;
