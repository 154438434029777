// prettier-ignore
import {
    BIT, BYTE, KILO, MEGA, GIGA, TERA, PETA, EXA, ZETTA,
    SINGLE, DOUBLE, TRIPLE,
    SUCCESS, DANGER, WARNING, PRIMARY, NEUTRAL
  } from 'lib/constants';

/**
 * Sizes
 */

export const sizes = { BIT, BYTE, KILO, MEGA, GIGA, TERA, PETA, EXA, ZETTA };

/**
 * Shadows
 */

export const shadows = { SINGLE, DOUBLE, TRIPLE };

/**
 * Colors
 */

export const colorNames = { SUCCESS, DANGER, WARNING, PRIMARY, NEUTRAL };

/**
 * Directions
 */
export const directions = {
  CENTER: 'center',
  TOP: 'top',
  RIGHT: 'right',
  BOTTOM: 'bottom',
  LEFT: 'left'
};
