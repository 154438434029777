import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { flexEqualColumn } from 'styling/helpers';
import Icon from 'chrome/Icon';
import Text from 'chrome/Text';
import Button from 'chrome/Button';

const baseStyles = ({ theme }) => css`
  width: 100%;
  margin: 0 ${theme.spacings.kilo};
  ${flexEqualColumn()}
`;

const StyledIconWithText = styled('div')(baseStyles);

const IconWithText = ({ children }) => (
  <StyledIconWithText>{children}</StyledIconWithText>
);

IconWithText.propTypes = {
  children: PropTypes.node
};

IconWithText.Icon = Icon;
IconWithText.Text = Text;
IconWithText.Button = Button;

export default IconWithText;
