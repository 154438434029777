import preload from './preload';
import render from './render';

const run = (config, elementId) => {
  render(
    preload({
      ...config
    }),
    elementId
  );
};

export default run;
