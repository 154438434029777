import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import DocumentsItem from './DocumentsItem';

const baseStyles = () => css`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100%;
`;

const Container = styled('div')(baseStyles);

const Documents = ({ isFetching, documentList }) => (
  <Container>
    {documentList.map((item, key) => (
      <DocumentsItem
        key={key}
        {...{
          isFetching,
          ...item
        }}
      />
    ))}
  </Container>
);

Documents.propTypes = {
  isFetching: PropTypes.bool,
  documentList: PropTypes.array
};

export default Documents;
