import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';

const baseStyles = () => css`
  min-width: 50px;
  margin-left: 20px;
`;

const sizeStyles = ({ size }) => css`
  width: ${size};
`;

const Col = styled('div')(baseStyles, sizeStyles);

Col.propTypes = {
  children: PropTypes.node
};

export default Col;
