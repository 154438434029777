import React from 'react';
import PropTypes from 'prop-types';

import IconWithText from 'chrome/IconWithText';
import Text from 'chrome/Text';

import { ReactComponent as WordIcon } from './assets/icons/word.svg';
import { ReactComponent as PdfIcon } from './assets/icons/pdf.svg';

const DocumentsItem = ({
  text,
  url,
  isWordDoc,
  buttonText,
  isFetching,
  downloadName
}) => (
  <IconWithText>
    <IconWithText.Icon>
      {isWordDoc ? <WordIcon /> : <PdfIcon />}
    </IconWithText.Icon>
    <IconWithText.Text as={'p'} size={Text.KILO}>
      {text}
    </IconWithText.Text>
    <IconWithText.Button
      as={'a'}
      href={url}
      isLoading={isFetching}
      download={downloadName}
      showPrefix={isFetching}
    >
      {buttonText}
    </IconWithText.Button>
  </IconWithText>
);

DocumentsItem.propTypes = {
  isFetching: PropTypes.bool,
  text: PropTypes.string,
  url: PropTypes.string,
  isWordDoc: PropTypes.bool,
  buttonText: PropTypes.string,
  downloadName: PropTypes.string
};

export default DocumentsItem;
