export const EXPORT_DOCUMENT_START = 'EXPORT_DOCUMENT_START';
export const exportDocumentStart = data => {
  return {
    type: EXPORT_DOCUMENT_START,
    data
  };
};

export const EXPORT_DOCUMENT_SUCCESS = 'EXPORT_DOCUMENT_SUCCESS';
export const exportDocumentSuccess = resp => {
  return {
    type: EXPORT_DOCUMENT_SUCCESS,
    resp
  };
};

export const EXPORT_DOCUMENT_FAILURE = 'EXPORT_DOCUMENT_FAILURE';
export const exportDocumentFailure = err => {
  return {
    type: EXPORT_DOCUMENT_FAILURE,
    err
  };
};

export const EXPORT_SET_FILENAME = 'EXPORT_SET_FILENAME';
export const exportSetFilename = name => {
  return {
    type: EXPORT_SET_FILENAME,
    name
  };
};

export const SET_IS_EXPORTED = 'SET_IS_EXPORTED';
export const setIsExported = () => {
  return {
    type: SET_IS_EXPORTED
  };
};
