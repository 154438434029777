import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';

const baseStyles = ({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: ${theme.elementSize.header};
`;

const Wrapper = styled('div')(baseStyles);

Wrapper.propTypes = {
  children: PropTypes.node
};

export default Wrapper;
