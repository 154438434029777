import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getEndpoint } from 'lib/routes';

import Documents from 'documents/Documents';

const DocumentsLearnMore = ({ documentList }) => (
  <Documents documentList={documentList} />
);

DocumentsLearnMore.propTypes = {
  documentList: PropTypes.array
};

const mapStateToProps = state => {
  const { config, documents } = state;

  const documentList = documents.learnMore.map(document => {
    const baseUrl = getEndpoint(config.apiUrl).documents;
    // const url = `${baseUrl}/${document.filename}`;
    const url = `${document.filepath}`;

    return {
      ...document,
      url
    };
  });

  return {
    documentList
  };
};

export default connect(mapStateToProps)(DocumentsLearnMore);
