import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { flexCenter } from 'styling/helpers';

const baseStyles = () => css`
  ${flexCenter()}
  list-style: none;
  width: 100%;
`;

const NavMenuList = styled('ul')(baseStyles);

NavMenuList.propTypes = {
  children: PropTypes.node
};

export default NavMenuList;
