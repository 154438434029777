import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ModalTrigger from 'modal/ModalTrigger';
import DocumentsExport from 'documents/DocumentsExport';

import { fetchDocument } from './actions';

const Generate = ({ exportBtnText, isDisabled, handleExportClick }) => (
  <ModalTrigger
    btnText={exportBtnText}
    onSelect={handleExportClick}
    extra={{
      isDisabled
    }}
  >
    <DocumentsExport />
  </ModalTrigger>
);

Generate.propTypes = {
  exportBtnText: PropTypes.string,
  isDisabled: PropTypes.bool,
  handleExportClick: PropTypes.func
};

Generate.defaultProps = {
  exportBtnText: 'Export to Word'
};

const mapStateToProps = state => {
  const { editor } = state;
  const { isSubmitted } = editor;
  const isDisabled = !isSubmitted;

  return {
    isDisabled
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleExportClick: () => dispatch(fetchDocument())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Generate);
