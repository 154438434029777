import {
  splitStringBeforeWord,
  getParagraphNodes,
  getFormattedTime,
  urlToFile
} from 'lib/utils';

const docType =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export const getExportFileUrl = resp =>
  urlToFile(
    `data:${docType};base64,${resp}`,
    `JobPosting_${getFormattedTime()}`,
    `${docType}`
  ).then(file => file);

export const buildParagraphDictionary = ({ allIds, byId }) =>
  allIds.reduce((acc, curr) => {
    const field = byId[curr];
    return {
      ...acc,
      [curr]: getParagraphNodes(field)
    };
  }, {});

export const buildExportDictionary = ({
  byId,
  allIds,
  allFindWordIds,
  byFindWordId
}) => {
  if (!allFindWordIds.length) {
    return buildParagraphDictionary({ allIds, byId });
  }

  return allIds
    .map(id => {
      const editorHtmlSplit = splitStringBeforeWord({
        str: getParagraphNodes(byId[id]),
        words: allFindWordIds.map(wordId => byFindWordId[wordId].text)
      });

      return {
        [id]: editorHtmlSplit.map(item =>
          item.reduce((acc, curr) => {
            return acc.concat(curr);
          }, [])
        )
      };
    })
    .reduce((arr, curr) => {
      return {
        ...arr,
        ...curr
      };
    }, {});
};

export const getReplacements = ({ allFindWordIds, byFindWordId }) =>
  allFindWordIds
    .map(id => {
      const { type, text } = byFindWordId[id];
      return {
        type,
        text
      };
    })
    .reduce((arr, { text, type }) => {
      return {
        ...arr,
        [text]: {
          type
        }
      };
    }, {});
