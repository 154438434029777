/**
 * Position
 */

export const TOP = 'top';
export const BOTTOM = 'bottom';
export const LEFT = 'left';
export const RIGHT = 'right';

/**
 * Alignment
 */

export const START = 'start';
export const END = 'end';
export const CENTER = 'center';

/**
 * Column
 */

export const MIN_COL_SPAN = 1;
export const MAX_COL_WIDTH = 100;
export const DEFAULT_BREAKPOINT = 'default';

/**
 * Sizes
 */

export const BIT = 'bit';
export const BYTE = 'byte';
export const KILO = 'kilo';
export const MEGA = 'mega';
export const GIGA = 'giga';
export const TERA = 'tera';
export const PETA = 'peta';
export const EXA = 'exa';
export const ZETTA = 'zetta';

/**
 * Tooltip Types
 */

export const ALERT = 'alert';

/**
 * Shadows
 */

export const SINGLE = 'single';
export const DOUBLE = 'double';
export const TRIPLE = 'triple';

/**
 * Colors
 */

export const SUCCESS = 'success';
export const DANGER = 'danger';
export const WARNING = 'warning';
export const NEUTRAL = 'neutral';
export const PRIMARY = 'primary';

/**
 * Search
 */

export const WHOLE_WORD = 'wholeWord';
export const SENTENCE = 'sentence';

/**
 * Text Updates
 */

export const REPLACEMENT = 'isReplacement';
export const REPLACED = 'isReplaced';
export const SUGGESTION = 'isSuggestion';

/**
 * RegEx
 */

const wholeWord = `\\b|\\b`;
const sentence = /\(?[^\.\?\!]+[\.!\?]\)?/g; // eslint-disable-line no-useless-escape

export const searchPattern = {
  [WHOLE_WORD]: wholeWord,
  [SENTENCE]: sentence
};
