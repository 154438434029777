import { getEndpoint } from 'lib/routes';

const headers = new Headers({
  'content-type': 'application/json'
});

export const generateDocument = ({ apiUrl }, data) => {
  const endpoint = getEndpoint(apiUrl);

  return fetch(endpoint.export, {
    method: 'POST',
    body: JSON.stringify(data),
    headers
  }).then(resp => resp.text());
};
