import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';

import Button from 'chrome/Button';

const baseStyles = () => css`
  display: flex;
  justify-content: flex-end;
  max-height: 100%;
  height: 100%;
  width: 100%;
  text-align: right;
`;

const Container = styled('div')(baseStyles);

const baseButtonItemStyles = ({ theme }) => css`
  margin-left: ${theme.spacings.kilo};
`;

const ButtonItem = styled('div')(baseButtonItemStyles);

const UserButtons = ({ buttons }) => (
  <Container>
    {buttons.map(({ text, url }, key) => (
      <ButtonItem key={key}>
        <Button onClick={() => window.location.assign(url)} type={'button'}>
          {text}
        </Button>
      </ButtonItem>
    ))}
  </Container>
);

UserButtons.propTypes = {
  buttons: PropTypes.array
};

UserButtons.defaultProps = {
  buttons: [
    {
      id: 'profile',
      text: 'Edit Profile',
      url: 'https://hirenext.charityvillage.com/profile/questions'
    },
    {
      id: 'logout',
      text: 'Log Out',
      url: 'https://hirenext.charityvillage.com/users/sign_out'
    }
  ]
};

export default UserButtons;
