import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import EditorList from './EditorList';

const Editor = ({ fields }) => <EditorList list={fields} />;

Editor.propTypes = {
  fields: PropTypes.array
};

const mapStateToProps = state => {
  const { editor } = state;
  const { fields } = editor;

  return {
    fields
  };
};

export default connect(mapStateToProps)(Editor);
